import autoktAxios, { BASE_API_URL, GITHUB_INTEGRATION_URL } from "./axios_autokt";
import githubAxios from "./axios_github";


const getFileContent = (payload)=>autoktAxios.post(`${BASE_API_URL}/files/single`,payload)
const getGithubFile = (payload)=>githubAxios.post(`${GITHUB_INTEGRATION_URL}/repositories/file`,payload)

const filesApi = {
    getFileContent,
    getGithubFile
}

export default filesApi
