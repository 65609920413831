import { useTheme } from "@emotion/react";
import { Box, Button, Divider, Input, OutlinedInput, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import userApi from "../api/user";
import { useNavigate } from "react-router-dom";
import { GitHub } from "@mui/icons-material";

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    console.log("access_token");
    setLoading(true);
    try {
      const {
        data: { access_token },
      } = await userApi.login({
        username,
        password,
      });
      console.log(access_token);
      if (access_token) {
        localStorage.setItem("USER_TOKEN", access_token);
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#111",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: "30%",
          aspectRatio: "1",
          minWidth: "400px",
          display: "flex",
          padding: 1,
          py: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box width={"70%"}>
          <Typography variant="h3" fontWeight={"600"} marginBottom={4}>
            Welcome to Auto
            <span
              style={{
                color: theme.palette.primary.main,
              }}
            >
              KT{" "}
            </span>
            <img
              src="https://autokt.io/wp-content/uploads/2023/08/AutoKT_transparent.png"
              height={"60px"}
            />
          </Typography>

          <Button
            fullWidth
            variant="contained"
            sx={{
              background: "#1f1f1f",
              color: "#fff",
              borderRadius: "5px",
              px: 2,
              py: 1,
            }}
            href="https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt&redirect_uri=https://autokt-backend.onrender.com/users/github-login"
            startIcon={<GitHub />}
          >
            Login with Github
          </Button>
          <Divider
            sx={{
              my: 1.5,
            }}
          >
            Or
          </Divider>
          <OutlinedInput
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
            sx={{
              marginBottom: "5px",
            }}
            placeholder="Email"
          />
          <OutlinedInput
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            sx={{
              marginBottom: "15px",
            }}
          />
          <Button disabled={loading} variant="contained" onClick={handleLogin}>
            Login
          </Button>
          <Typography
            sx={{
              mb: 2,
            }}
          >
            New here?{" "}
            <a
              href="/signup"
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              Create an account.
            </a>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
