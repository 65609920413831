import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import githubIntegrationApi from "../../../api/github";
import organisationApi from "../../../api/organisation";
import ModeTypography from "../../../components/ModeTypography";
import { useTheme } from "@emotion/react";
import { AddCircle } from "@mui/icons-material";
import { UserContext } from "../../root";

const ListDocumentation = () => {
  const user = useContext(UserContext);
  const [workspaces, setWorspaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [workspaceQuery, setWorkspaceQuery] = useState("");
  const [organisation, setOrganisation] = useState({});

  const navigate = useNavigate();
  const theme = useTheme();

  const fetchRepos = async () => {
    console.log("ran");
    setLoading(true);
    if (user?.org_id) {
      try {
        const { data } = await githubIntegrationApi.listUserRepos();
        console.log("data");
        console.log(data);
        setWorspaces([...data.map((repo) => ({ ...repo, type: "repo" }))]);
      } catch (err) {
        console.log(err);
      }
    }
  };
  console.log([
    {
      ...user,
      type: "user",
      subtitle: "This is where all your personal documentation can be added.",
    },
    { ...organisation },
    ...workspaces,
  ]);

  const filteredWorkspaces = [
    {
      ...user,
      type: "user",
      subtitle: "This is where all your personal documentation can be added.",
    },
    { ...organisation },
    ...workspaces,
  ].filter(
    (workspace) =>
      workspace?.name?.toLowerCase().includes(workspaceQuery.toLowerCase()) ||
      workspace?.username?.toLowerCase().includes(workspaceQuery.toLowerCase())
  );

  const fetchOrganisations = async () => {
    setLoading(true);
    try {
      const { data } = await organisationApi.getOrganisation();
      console.log(data);
      setOrganisation({
        ...data,
        subtitle: "This is where your team level documentation can be added.",
        type: "team",
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganisations();
  }, []);

  useEffect(() => {
    fetchRepos();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box width={"100%"} py={2}>
      <Box px={2}>
        <ModeTypography variant="h4" sx={{ mb: 2 }}>
          Your workspaces
        </ModeTypography>
        <TextField
          value={workspaceQuery || undefined}
          onChange={(e) => setWorkspaceQuery(e.target.value)}
          placeholder="Search workspaces"
        />
      </Box>

      <Grid
        mx={"auto"}
        columnGap={2}
        alignItems={"stretch"}
        justifyContent={"space-between"}
        width={"85%"}
        container
      >
        {filteredWorkspaces?.map((workspace) => (
          <Grid
            item
            xs={6}
            md={3}
            elevation={4}
            sx={{
              wordBreak: "break-word",
              height: "200px",
              my: 1,
              mx: 2,
              py: 3,
              px: 4,
            }}
            key={workspace?.name}
          >
            <Paper
              sx={{ height: "100%", width: "100%", px: 2, py: 1, cursor: "pointer" }}
              onClick={() =>
                navigate(`/documentation/${workspace?.type}/${encodeURIComponent(workspace?.name)}`)
              }
            >
              <Typography color={theme.palette.primary.main} variant="h5" mb={2}>{`${
                workspace?.name || workspace?.username
              }'s ${workspace?.type !== "repo" ? workspace?.type : ""} workspace`}</Typography>
              <Typography variant="caption">
                {workspace?.subtitle || `The workspace for ${workspace?.name} repository`}
              </Typography>
            </Paper>
          </Grid>
        ))}

        <Grid
          item
          xs={6}
          md={3}
          elevation={4}
          sx={{
            wordBreak: "break-word",
            height: "200px",
            my: 1,
            mx: 2,
            py: 3,
            px: 4,
          }}
        >
          <Paper
            sx={{
              height: "100%",
              opacity: "0.6",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              px: 2,
              py: 1,
              cursor: "pointer",
              ":hover": { opacity: "1" },
            }}
            onClick={() => navigate(`/repositories/add`)}
          >
            <AddCircle
              sx={{
                m: "auto",
                fontSize: "5rem",
              }}
            />
            <ModeTypography>Add repository workspace</ModeTypography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListDocumentation;
