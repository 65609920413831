import { Box, Button, Paper, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import userApi from "../api/user";
import Loader from "../components/Loader";

const VerifyEmail = () => {
  const [verifyResponse, setVerifyResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const verifyEmail = async () => {
    const payload = {
      token: searchParams.get("token"),
      user_id: searchParams.get("userId"),
    };
    setLoading(true);
    if (Object.values(payload).reduce((agr, curr) => (curr ? true : false), false)) {
      try {
        const { data } = await userApi.verifyEmail(payload);
        console.log(data);
        setVerifyResponse(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      setVerifyResponse({
        error: (
          <>
            <Typography variant="h4">
              Thank you for signing up to Auto
              <span style={{ fontWeight: "bold", color: theme.palette.primary.main }}>KT</span>,
              please verify your email to proceed!
            </Typography>
            <Typography my={2} fontWeight="bold">
              💡Tip: If you don't see the email in your inbox, make sure to check the{" "}
              <u>spam/junk folder.</u>
            </Typography>
            <Typography>
              Already verified? Click{" "}
              <a style={{ color: theme.palette.primary.main }} href="/login">
                here to login.
              </a>
            </Typography>
          </>
        ),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#111",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: "30%",
          minWidth: "400px",
          display: "flex",
          px: 6,
          py: 4,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <ShowVerifyResponse
          verifyResponse={verifyResponse}
          userEmail={searchParams.get("email") || ""}
          signup={searchParams.get("signup") || false}
        />
      </Paper>
    </Box>
  );
};

const ShowVerifyResponse = ({ verifyResponse, userEmail, signup = false }) => {
  const message = verifyResponse?.success || verifyResponse?.error;
  const [resendEmail, setResendEmail] = useState(userEmail);
  const [emailSent, setEmailSent] = useState("");
  const theme = useTheme();

  const handleResendEmail = async () => {
    if (resendEmail) {
      try {
        const { data } = await userApi.resendVerificationEmail({
          email: resendEmail,
        });
        setEmailSent(data?.success || data?.error);
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <Typography variant="h4">{message}</Typography>
      {verifyResponse?.success && (
        <>
          <Typography>
            Click{" "}
            <a style={{ color: theme.palette.primary.main }} href="/login">
              here to login.
            </a>
          </Typography>
        </>
      )}
      {!emailSent && !signup && verifyResponse?.error && (
        <>
          <Typography mt={2}>Resend confimation email</Typography>
          <Typography my={2} fontWeight="bold">
            💡Tip: Make sure to check the <u> spam/junk folder!</u>
          </Typography>
          <TextField
            sx={{
              my: 2,
            }}
            onChange={(e) => setResendEmail(e.target.value)}
            value={resendEmail}
            label="Email"
            variant="outlined"
          />

          <Button onClick={handleResendEmail} color="primary" variant="contained">
            Send confirmation mail
          </Button>
        </>
      )}
      {emailSent && !signup && <Typography mt={2}>{emailSent}</Typography>}
    </>
  );
};

export default VerifyEmail;
