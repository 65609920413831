export const parseOnboardingStep = (user)=>{
  const completed = {0: false, 1:false, 2: false}
  let activeStep = 0
  if(user?.integrations?.length>0){
    completed[0] = true
    activeStep=1
    if(user?.integrations[0]?.installation_id){
      completed[1] = true
      completed[2] = true
      activeStep=2
    }
  }

  return [activeStep, completed]
}
