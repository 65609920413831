export const parseGeneratedFilename = (filename) => {
  if (!filename) {
    return "";
  }
  return filename.split("/docs/").slice(-1)[0].split("/main.md")[0];
};

export const parseUserFilename = (filename) => {
  if (!filename) {
    return "";
  }
  return filename.split("/user/").slice(-1)[0].split(".md")[0];
};

export const parseFilename = (filename, docType) => {
  if (docType === "MANUAL") {
    return filename;
  }
  return filename.split("/docs/").slice(-1)[0].split("/main.md")[0];
};

export const parseFilenameFromDirectory = (filepath) => filepath.split("/docs/").slice(-1)[0];

export const getObjectSafely = (obj, key) => {
  if (obj) {
    if (obj[key]) {
      return obj[key];
    }
  }
  return {};
};
export const parseGeneratedFilenameWithVersion = (filename, version) => {
  console.log("FILTER");
  console.log(filename);

  return filename.replace("/main.md", `/${version}.md`);
};

export const parseDocVersion = (version) => {
  return version.split("/").slice(-1)[0].split(".md")[0];
};

export const checkCreateMode = (pathname) => {
  return pathname.split("/").slice(-1)[0] === "create";
};

export const handleCmdKeys = (event) => {
  let charCode = String.fromCharCode(event.which).toLowerCase();
  if (event.ctrlKey && charCode === "s") {
    event.preventDefault();
    console.log("Ctrl + S pressed");
  }

  // For MAC we can use metaKey to detect cmd key

  if (event.metaKey && charCode === "s") {
    event.preventDefault();
    console.log("Cmd + S pressed");
  }
};

export const handleCmdKeysGenerator = (
  onSave,
  onKcmd = () => {
    console.log("K pressed");
  }
) => {
  return (event) => {
    console.log(event);
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey) {
      if (charCode === "s") {
        console.log("Ran");
        onSave(event);
        event.preventDefault();
      }
      if (charCode === "k") {
        event.stopPropagation();
        onKcmd(event);
        event.preventDefault();
      }
    }

    // For MAC we can use metaKey to detect cmd key

    if (event.metaKey) {
      if (charCode === "s") {
        event.preventDefault();
        onSave(event);
      }
      if (charCode === "k") {
        event.stopPropagation();
        onKcmd(event);
        event.preventDefault();
      }
    }
  };
};
