import autoktAxios, { BASE_API_URL } from "./axios_autokt";


const generateDocumentation = (payload)=>autoktAxios.post(`${BASE_API_URL}/engine/generate-documentation`,payload)
const getDocumentation = (payload)=>autoktAxios.post(`${BASE_API_URL}/engine/get-documentation`,payload)
const getDocVersions = (payload)=>autoktAxios.post(`${BASE_API_URL}/engine/documentation/versions`,payload)
const updateDocumentation = (payload)=>autoktAxios.put(`${BASE_API_URL}/engine/documentation`,payload)
const getVersions = (fullRepoName)=>autoktAxios.get(`${BASE_API_URL}/engine/versions?full_repo_name=${fullRepoName}`)
const listDocs =  (payload)=>autoktAxios.post(`${BASE_API_URL}/engine/documentation`, payload)
const getQueryResponse = (payload)=> autoktAxios.post(`${BASE_API_URL}/engine/query`,payload)

const engineApi = {
    generateDocumentation,
    listDocs,
    getDocumentation,
    getVersions,
    getDocVersions,
    updateDocumentation,
    getQueryResponse
}

export default engineApi