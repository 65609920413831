import React, { useState } from "react";
import { Box, Button, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import docsApi from "../../../../../api/docs";
import MDEditor from "@uiw/react-md-editor";
import { ContentCopy } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import engineApi from "../../../../../api/engine";

const Ask = ({ documentation, handleSetAiRelatedDoc }) => {
  const [query, setQuery] = useState("");
  const [asking, setAsking] = useState(false);
  const [response, setResponse] = useState("");
  const [docs, setDocs] = useState([]);
  const theme = useTheme();

  const handleDocQuery = async () => {
    setAsking(true);
    try {
      const { data } = await engineApi.getQueryResponse({
        query,
      });
      console.log(data);
      setResponse(data["query_response"]["response"]);
      if (data["query_response"]["docs"]) {
        setDocs([...data["query_response"]["docs"]]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAsking(false);
    }
  };
  const handleQuery = async () => {
    setAsking(true);
    try {
      const {
        data: { content },
      } = await docsApi.askDoc({
        query,
        documentation,
      });
      console.log(content);
      setResponse(content);
    } catch (err) {
      console.error(err);
    } finally {
      setAsking(false);
    }
  };

  return (
    <>
      <Typography>Ask AutoKT (BETA)</Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          fontSize: "0.75rem",
        }}
      >
        Note: AutoKT AI uses your existing documentation to answer any queries.
      </Typography>

      <TextField
        sx={{ mb: 1 }}
        fullWidth
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button disabled={asking} sx={{ mb: 2 }} variant="outlined" onClick={handleDocQuery}>
        Ask AutoKT
      </Button>

      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        {response && !asking && (
          <ContentCopy
            sx={{
              cursor: "pointer",
              ml: "auto",
              color: "secondary.main",
            }}
            onClick={async () => {
              await navigator.clipboard.writeText("\n" + response);
              console.log("Content copied to clipboard");
            }}
          />
        )}
        {asking ? (
          <LinearProgress />
        ) : (
          <MDEditor.Markdown
            wrapperElement={{
              "data-color-mode": theme.palette.mode,
            }}
            style={{
              background: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
              maxHeight: "280px",
              overflowY: "auto",
            }}
            source={response ?? ""}
          />
        )}
        {!asking && docs.length > 0 && (
          <Box mt={2} bgcolor={theme.palette.mode === "dark" ? "#1f1f1f" : "#fff"}>
            <Typography fontWeight={"bold"} color={"primary.main"}>
              Related documentation
            </Typography>
            {docs.map((doc) => (
              <Typography
                onClick={() => handleSetAiRelatedDoc(doc)}
                sx={{
                  py: 1,
                  cursor: "pointer",
                  wordBreak: "break-all",
                  borderBottom: "1px solid " + theme.palette.primary.main,
                }}
              >
                {doc.filepath}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Ask;
