export const DOC_TYPE = { 3: "GENERATED", 2: "MANUAL" };

export const parseDocType = (docType) => {
  if (docType === "GENERATED") {
    return "Generated";
  }

  return "User created";
};

export const parseFilename = (filename, docType) => {
  if (docType === "MANUAL") {
    return filename.split("/user/").slice(-1)[0].split(".md")[0];
  }
  return filename.split("/docs/").slice(-1)[0].split(".md")[0];
};

export const ALWAYS_VISIBLE_SCROLLBAR_STYLES = {
  "&::-webkit-scrollbar": {
    webkitAppearance: "none",
    width: 6,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: 2,
    backgroundColor: "#293a6b",
  },
};
