import { Box, TextField, Typography, useTheme } from '@mui/material'
import React from 'react'

const Versioning = ({repo}) => {
    const theme  = useTheme()
  return (
    <Box>  <Typography variant='h5' sx={{
        fontWeight:"500",
        pb:2
    }}>
        Versioning
    </Typography>
    <Typography>
        AutoKT keeps versions of your documentation as your codebase changes. If documentation already exists for a file a new version of it is saved marked with the PR name.
        <br />From all these versions AutoKT maintains a version that is the <code style={{
            color: theme.palette.secondary.main,
            fontWeight:"600",
            backgroundColor: theme.palette.background.default,
            py:0.5,
            px:1
        }}>source of truth.</code>
        <br /> <br />All approved documentation is saved to this version called <span style={{
            color: theme.palette.secondary.main,
            fontWeight:"600",
            backgroundColor: theme.palette.background.default,
            py:0.5,
            px:2
        }}>main.</span>
    </Typography>

  
</Box>
  )
}

export default Versioning