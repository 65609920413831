import { Box } from '@mui/material'
import React from 'react'
import ModeTypography from '../../../components/ModeTypography'

const ShowUsers = ({users=[]}) => {
  return (
    <Box>
      {users.map((user)=><ModeTypography>{user.username}</ModeTypography>)}
    </Box>
  )
}

export default ShowUsers
