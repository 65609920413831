import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ModeTypography from "../../../components/ModeTypography";

const NoWorkspaceDoc = () => {
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      pt={6}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <ModeTypography variant="h6" mb={2}>
        You have no docs in this workspace, let's create one to get started!
      </ModeTypography>
      <Button variant="contained" onClick={() => navigate("create")}>
        Create doc
      </Button>
    </Box>
  );
};

export default NoWorkspaceDoc;
