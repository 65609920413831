import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./error-page";
import Root from "./routes/root";
import Login from "./routes/login";
import { ThemeProvider } from "@emotion/react";
import Onboarding from "./routes/onboarding/Onboarding";
import GithubIntegration from "./routes/github-integration";
import Dashboard from "./routes/Dashboard/Dashboard";
import AddRepos from "./routes/Dashboard/AddRepo/AddRepos";
import ListRepos from "./routes/Dashboard/ListRepos";
import Signup from "./routes/signup";
import GithubOnboarding from "./routes/onboarding/GithubOnboarding";
import getDesignTokens from "./theme";
import { createTheme } from "@mui/material";
import Query from "./routes/Dashboard/Query";
import Organisation from "./routes/Organisation/Organisation";
import ShowOrganisation from "./routes/Organisation/ShowOrganisation/ShowOrganisation";
import VerifyEmail from "./routes/verify-email";
import Settings from "./routes/Settings/Settings";
import TeamSettings from "./routes/Settings/TeamSettings";
import Documentation from "./routes/Documentation/Documentation";
import ListDocumentation from "./routes/Documentation/ListDocumentation/ListDocumentation";
import ShowWorkspace from "./routes/Documentation/ShowWorkspace/ShowWorkspace";
import OnboardingForm from "./routes/onboarding/OnboardingForm";
import DocumentationEditor from "./routes/Documentation/DocumentationEditor/DocumentationEditor";
import UnderMaintenance from "./routes/under-maintenance";
import LoginExtension from "./routes/login-extension";
import SignupExtension from "./routes/signup-extension";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/onboard",
        element: <Onboarding />,
        children: [
          {
            path: "/onboard/github",
            element: <GithubOnboarding />,
          },
          {
            path: "/onboard",
            element: <OnboardingForm />,
          },
        ],
      },
      {
        path: "/repositories",
        element: <Dashboard />,
        children: [
          {
            path: "/repositories/add",
            element: <AddRepos />,
          },
          {
            path: "/repositories/query",
            element: <Query />,
          },
          {
            path: "/repositories",
            element: <ListRepos />,
          },
        ],
      },
      {
        path: "/team",
        element: <Organisation />,
        children: [
          {
            path: "/team",
            element: <ShowOrganisation />,
          },
        ],
      },
      {
        path: "/documentation",
        element: <Documentation />,
        children: [
          {
            path: "/documentation",
            element: <ListDocumentation />,
          },
          {
            path: "/documentation/:type/:workspace",
            element: <ShowWorkspace />,
          },
          {
            path: "/documentation/:type/:workspace/create",
            element: <DocumentationEditor />,
          },
          {
            path: "/documentation/:type/:workspace/:filename",
            element: <DocumentationEditor />,
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "/settings/teams",
            element: <TeamSettings />,
          },
        ],
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/extension/login",
    element: <LoginExtension />,
  },
  {
    path: "/extension/signup",
    element: <SignupExtension />,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "github-integration",
    element: <GithubIntegration />,
  },
  {
    path: "verify-email",
    element: <VerifyEmail />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={createTheme(getDesignTokens("dark"))}>
    <RouterProvider router={router} />
    {/* <UnderMaintenance /> */}
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
