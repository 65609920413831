import axios from "axios"
import autoktAxios, { BASE_API_URL, GITHUB_INTEGRATION_URL } from "./axios_autokt"
import githubAxios from "./axios_github"

const encodePayload = (payload)=>{
    return Object.keys(payload).map((key)=>`${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`).join("&")
}


const signup  = (payload)=>autoktAxios.post(`${BASE_API_URL}/users/signup`, payload,{
    headers:{
        'Content-Type': 'application/json'
    }
})
const profile = ()=>autoktAxios.get(`${BASE_API_URL}/users/me`)

const login  = (payload)=>autoktAxios.post(`${BASE_API_URL}/users/token`, encodePayload(payload),{
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


const addIntegration = (payload)=> autoktAxios.post(`${BASE_API_URL}/users/integration`,payload,{
    headers:{
        'Content-Type': 'application/json'
    }
})

const verifyEmail = (payload)=> autoktAxios.post(`${BASE_API_URL}/users/verify`,payload,{
    headers:{
        'Content-Type': 'application/json'
    }
})

const resendVerificationEmail = (payload)=> autoktAxios.post(`${BASE_API_URL}/users/resend-confirmation`,payload,{
    headers:{
        'Content-Type': 'application/json'
    }
})


const getInstallations = (payload)=> githubAxios.post(`${GITHUB_INTEGRATION_URL}/installations`,payload)

const updateInstallationId = (payload)=>autoktAxios.put(`${BASE_API_URL}/users/integration`,payload,{
    headers:{
        'Content-Type': 'application/json'
    }
})

const getUserOrgs = ()=>autoktAxios.get(`${BASE_API_URL}/users/organisations`)


const userApi = {
    login,
    signup,
    profile,
    addIntegration,
    getInstallations,
    updateInstallationId,
    getUserOrgs,
    verifyEmail,
    resendVerificationEmail
}

export default userApi
