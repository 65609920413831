import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import docsApi from "../../../api/docs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

const ListDocumentationSearch = () => {
  const [docs, setDocs] = useState([]);
  const [searchDoc, setSearchDoc] = useState("");
  const [loading, setLoading] = useState(true);
  const [focused,setFocused ] = useState(false)
  const navigate = useNavigate()

  const filteredDocsLabels = docs.map((doc)=>({label: doc?.filename, id:doc['_id'],value:doc?.filename, workspace_type: doc?.workspace_type}));

  const fetchDocs = async () => {
    try {
      const { data } = await docsApi.listDocs({});
      console.log(data[0]);
      setDocs([...data]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if(focused || docs?.length==0){
      fetchDocs();
    }
  }, [focused]);

  useEffect(()=>{
    if(searchDoc?.label){
        const docUrl = `/documentation/${searchDoc?.workspace_type.toLowerCase()}/${encodeURIComponent(searchDoc?.label.split("/docs/")[0])}/${encodeURIComponent(searchDoc?.label)}`
        navigate(docUrl)
    }
  },[searchDoc])



  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={filteredDocsLabels}
        sx={{}}
        freeSolo
        fullWidth
        value={searchDoc || undefined}
        onChange={(e, newVal) => setSearchDoc(newVal)}
        renderInput={(params) => <TextField onBlur={(e)=>setFocused(false)} onFocus={(e)=>setFocused(true)} {...params}
        placeholder="Search documentation"
         />}
      />
    </>
  );
};

export default ListDocumentationSearch;
