import React from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

const Organisation = () => {


  return (
    <Box position={'relative'} >
        <Outlet />
    </Box>
  )
}

export default Organisation
