import { Box, Typography } from '@mui/material'
import React from 'react'
import ModeTypography from '../../../../../components/ModeTypography'

const Overview = ({repo}) => {
  return (
    <Box>
        <ModeTypography variant='h6'>
            Welcome! This setup will guide you through adding the repository {repo['full_name']} to your AutoKT installation.
        </ModeTypography>
        <ModeTypography>
            <br />Let's begin!
        </ModeTypography>
    </Box>
  )
}

export default Overview
