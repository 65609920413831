import React, { useContext, useEffect, useState } from 'react'
import githubIntegrationApi from '../../../api/github'
import { UserContext } from '../../root'
import { useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import AddRepoForm from './AddRepoForm/AddRepoForm'
const AddRepos = () => {
    const user = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [repos, setRepos] = useState([])
    const[newRepos, setNewRepos] = useState([])
    const [selectedRepoId, setSelectedRepoId] = useState("")

    const navigate = useNavigate()

    const filteredRepos = search? newRepos.filter((repos)=>repos?.name.toUpperCase().includes(search.toUpperCase())) :[...newRepos]

    const fetchRepos = async()=>{
        console.log("ran")
        try{
            const {data} = await githubIntegrationApi.listUserRepos()
            // console.log("data")
            const repos = data.map((repo)=>repo.name)
            // console.log(repos)

            setRepos([...repos])
        }catch(err){
            console.log(err)
        }
    }





    const fetchNewRepos = async()=>{
        setLoading(true)
        try{
            console.log("user")
            console.log(user)
            const github_integration = user.integrations.find((integration)=> integration.type==="GITHUB")
            const response = await githubIntegrationApi.listRepos({...github_integration, user_token: localStorage.getItem("USER_TOKEN")})
            console.log("data")
            if(response?.data){
                const newRepos = response.data.repositories.filter((newRepo)=> repos.indexOf(newRepo['full_name'])==-1)
                console.log(newRepos)
                setNewRepos([...newRepos])
            }

        }catch(err){
            console.log(err)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetchRepos()
    },[])

    useEffect(()=>{
        if(user.username){
            fetchNewRepos()
        }

    },[user.username, repos.length])


    if(loading){
        return <Box sx={{
            position:"fixed",
            top:"50vh",
            left:"50vw"
        }}><CircularProgress color='secondary' /></Box>
    }

    if(selectedRepoId){
        return <AddRepoForm repo={newRepos.find((repo)=>repo.id===selectedRepoId)} />
    }

  return (
    <Box width={"100%"} sx={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    }} >
    <TextField
          placeholder="Enter repo name"
          id="outlined-start-adornment"
          sx={{  width:"90%",marginTop:2}}
          value={search}
          onChange={(e)=>{
            setSearch(e.target.value)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
        />
    <Grid container columnSpacing={4} marginTop={2} paddingX={2} position={'relative'} rowSpacing={2}>

        {/* Accesible repos:{newRepos.length} */}
        {filteredRepos?.map((repo)=>{
           return (
            <Grid item xs={3} key={repo.id}>
            <Paper sx={{
                padding:4,
                position:"relative"
            }} >

                <Box sx={{
                    wordBreak:"break-word"
                }}>
                <Typography>{repo['full_name']}<a href={repo['html_url']} target='_blank' rel='noreferrer'><OpenInNewIcon sx={{
                    color:"primary.main",
                    position:"absolute",
                    wordBreak:"break-word",
                    top:0,
                    right:0
                }} /></a></Typography>
                <Button variant='outlined' color='secondary' onClick={()=>setSelectedRepoId(repo.id)}>Add repo</Button>
                </Box>
            </Paper>
            </Grid>
            )
        })}
    </Grid>
    </Box>
  )
}

export default AddRepos
