import axios from "axios"
import autoktAxios, { BASE_API_URL } from "./axios_autokt"
import githubAxios, { GITHUB_INTEGRATION_URL } from "./axios_github"


const getOrganisation = ()=>autoktAxios.get(`${BASE_API_URL}/organisations/show`)
const getOrgUsers = (type)=>autoktAxios.get(`${BASE_API_URL}/organisations/users?type=${type}`)
const getOrgMembers = (payload)=> githubAxios.post(`${GITHUB_INTEGRATION_URL}/organisations/${payload.name}/members`,payload)

const organisationApi = {
  getOrganisation,
  getOrgUsers,
  getOrgMembers
}

export default organisationApi
