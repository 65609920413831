import "./styles/all.css";
import { ExtensionPriority } from "remirror";

import { useTheme } from "@emotion/react";
import jsx from "refractor/lang/jsx.js";
import typescript from "refractor/lang/typescript.js";
import python from "refractor/lang/python";
import {
  CommandButton,
  CommandButtonGroup,
  CreateTableButton,
  DataTransferButtonGroup,
  EditorComponent,
  FormattingButtonGroup,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  ListButtonGroup,
  PlaceholderExtension,
  Remirror,
  TableDeleteButton,
  ToggleCodeBlockButton,
  Toolbar,
  useRemirror,
} from "@remirror/react";
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TableExtension,
  TaskListExtension,
  TrailingNodeExtension,
  UnderlineExtension,
} from "remirror/extensions";
import { SlashMenuExtension } from "./SlashCommandExtension";
import { useRef, useState } from "react";
import SlashMenu from "./SlashMenu/SlashMenu";

const SingleMDEditor = ({ value, setValue, preview, showCodeDiff, ...props }) => {
  const theme = useTheme();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 280 });
  const [activeSlashMenu, setActiveSlashMenu] = useState(-1);

  const menuRef = useRef();

  const showMenu = (view) => {
    if (!view) return;

    const { from, to } = view.state.selection;
    const start = view?.coordsAtPos(from);
    const end = view?.coordsAtPos(to);

    // Calculate the menu position
    let yOffset = (start?.top + end?.top) / 2;
    let left = (start?.left + end?.right) / 2;
    left = `${left}px`;

    if (yOffset > window.screen.height / 2) {
      console.log(yOffset);
      let top = yOffset - 280;
      top = `${top}px`;
      console.log(top);
      setMenuPosition(() => ({ top, left }));
    } else {
      let top = yOffset + 24;
      top = `${top}px`;

      setMenuPosition(() => ({ top, left }));
    }

    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  const { manager, state } = useRemirror({
    extensions: () => [
      new PlaceholderExtension({ placeholder: "Enter / to write with AutoKT AI." }),
      new LinkExtension({ autoLink: true, openLinkOnClick: true }),
      new BoldExtension(),
      new StrikeExtension(),
      new ItalicExtension(),
      new HeadingExtension(),
      new BlockquoteExtension(),
      new UnderlineExtension(),
      new BulletListExtension({ enableSpine: true }),
      new OrderedListExtension(),
      new ListItemExtension({ priority: ExtensionPriority.High, enableCollapsible: true }),
      new CodeExtension(),
      new CodeBlockExtension({ supportedLanguages: [jsx, typescript, python] }),
      new TrailingNodeExtension(),
      new TaskListExtension(),
      new TableExtension(),
      new MarkdownExtension({ copyAsMarkdown: true }),
      /**
       * `HardBreakExtension` allows us to create a newline inside paragraphs.
       * e.g. in a list item
       */
      new HardBreakExtension(),
      new SlashMenuExtension({
        showMenu,
        hideMenu,
      }),
    ],
    selection: "start",
    content: value,
    stringHandler: "markdown",
  });

  return (
    <div className="remirror-theme" style={{ height: showCodeDiff ? "50%" : "calc(100% - 200px)" }}>
      <Remirror
        manager={manager}
        initialContent={state}
        onChange={({ helpers }) => {
          setValue(helpers.getMarkdown());
        }}
      >
        <Toolbar
          style={{
            padding: "10px 0px",
          }}
        >
          <HistoryButtonGroup />

          <DataTransferButtonGroup />
          <HeadingLevelButtonGroup />
          <FormattingButtonGroup />
          <ToggleCodeBlockButton />
          <ListButtonGroup />
          <CreateTableButton />
          <TableDeleteButton />
          <CommandButtonGroup>
            <CommandButton
              style={{
                background: theme.palette.primary.main,
                color: "white",
              }}
              icon={"chatNewLine"}
              enabled
              commandName="Write with AI"
              displayShortcut
              label="Write with AI (/)"
              onSelect={() => setMenuVisible((isMenuVisible) => !isMenuVisible)}
            />
          </CommandButtonGroup>
        </Toolbar>
        <EditorComponent />
        {isMenuVisible && (
          <SlashMenu
            ref={menuRef}
            value={value}
            activeSlashMenu={activeSlashMenu}
            setActiveSlashMenu={setActiveSlashMenu}
            menuPosition={menuPosition}
            setMenuVisible={setMenuVisible}
            isMenuVisible={isMenuVisible}
          />
        )}
      </Remirror>
    </div>
  );
};

export default SingleMDEditor;
