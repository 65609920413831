import { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import userApi from "../api/user";
import {
  Box,
  Button,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import getDesignTokens from "../theme";
import MaterialUISwitch from "./components/MuiSwitch";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import ListDocumentationSearch from "./Documentation/ListDocumentation/ListDocumentationSearch";
import { parseOnboardingStep } from "../utils/onboarding";

export const UserContext = createContext(null);
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function Root() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({});
  const [mode, setMode] = useState(theme.palette.mode);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const showMenu = Boolean(menuAnchor);
  const isOnboarded =
    !user?.username ||
    (user?.approved && user?.integrations?.length > 0 && user?.integrations[0]?.installation_id);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode]
  );

  const [completedStep, completed] = parseOnboardingStep(user);

  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };

  const innerTheme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const fetchUser = async () => {
    try {
      const { data } = await userApi.profile();
      console.log(data);
      setUser({ ...data });
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        localStorage.setItem("USER_TOKEN", "");
      }
    }
  };
  useEffect(() => {
    const userToken = localStorage.getItem("USER_TOKEN");
    if (!userToken) {
      navigate("/login");
    } else {
      if (!user?.username) {
        fetchUser();
      }
    }
  });

  useEffect(() => {
    if (user?.username) {
      if (user?.approved) {
        if (user.integrations.length > 0) {
          const githubIntegration = user.integrations.find(
            (integration) => integration.type === "GITHUB"
          );
          if (!githubIntegration?.installation_id && location.pathname === "/") {
            navigate("/onboard");
          }
          if (githubIntegration?.installation_id && location.pathname === "/") {
            navigate("/repositories");
          }
        } else {
          if (location.pathname === "/" || location.pathname.includes("/repositories")) {
            navigate(`/documentation/user/${user?.name}/create`);
          }
        }
      } else {
        navigate("/verify-email?email=" + user.username);
      }
    }
  }, [user.username, location.pathname]);

  return (
    <div data-theme={mode}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={innerTheme}>
          <Box
            sx={{
              width: "100%",
              minHeight: "100vh",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "background.paper",
            }}
          >
            <Paper
              sx={{
                alignItems: "center",
                height: "70px",
                display: "flex",
                justifyContent: "space-between",
                background: mode === "dark" ? "#1f1f1f" : "#fff",
                paddingX: 2,
                zIndex: "4",
              }}
              elevation={2}
            >
              <Box
                style={{
                  cursor: "pointer",
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => navigate("/repositories")}
              >
                <Typography
                  variant="h6"
                  marginLeft={0.5}
                  borderBottom={"2px solid " + theme.palette.primary.main}
                >
                  AutoKT
                </Typography>
              </Box>
              <Box flexGrow={"1"} px={1} pl={2} py={2} display={"flex"} alignItems={"center"}>
                <ListDocumentationSearch />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  marginLeft={2}
                  marginRight={2}
                  onClick={() => {
                    if (isOnboarded) {
                      navigate("/repositories");
                    } else {
                      navigate("/onboard");
                    }
                  }}
                >
                  Repositories
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  marginLeft={2}
                  marginRight={2}
                  onClick={() => {
                    navigate("/documentation");
                  }}
                >
                  Documentation
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  marginLeft={2}
                  marginRight={2}
                  onClick={() => {
                    navigate("/team");
                  }}
                >
                  Team
                </Typography>
                <MaterialUISwitch
                  checked={mode === "dark"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMode("dark");
                    } else {
                      setMode("light");
                    }
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={() => navigate("/repositories/query")}
                >
                  Query
                </Button>

                <Box
                  display="flex"
                  id="menu-anchor"
                  sx={{
                    cursor: "pointer",
                  }}
                  alignItems="center"
                >
                  <Avatar
                    sx={{
                      bgcolor: "secondary.main",
                      mr: 1,
                      color: mode === "dark" ? "black" : "white",
                    }}
                  >
                    {(user?.name || user?.username)?.split("")[0]}
                  </Avatar>
                  <Typography onMouseOver={handleClick}>{user?.name || user?.username}</Typography>
                  <Tooltip title="Logout" placement="bottom">
                    <IconButton
                      onClick={() => {
                        localStorage.setItem("DOC_TOUR_SHOWN", "");
                        localStorage.setItem("USER_TOKEN", "");
                        navigate("/login");
                      }}
                    >
                      <LogoutIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  open={showMenu}
                  width="100%"
                  anchorEl={menuAnchor}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    <SettingsIcon
                      sx={{
                        mr: 1,
                      }}
                    />{" "}
                    Settings
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      localStorage.setItem("USER_TOKEN", "");
                      localStorage.setItem("DOC_TOUR_SHOWN", "");
                      navigate("/login");
                    }}
                  >
                    <LogoutIcon
                      sx={{
                        mr: 1,
                      }}
                    />{" "}
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Paper>
            {!isOnboarded && !location.pathname.includes("/onboard") && (
              <Alert
                sx={{ cursor: "pointer", fontSize: "1rem" }}
                onClick={() => navigate("/onboard")}
                severity="info"
              >
                Let AutoKT take care of your documentation! Complete your onboarding to integrate
                your codebase and get started. (
                {`${completedStep}/${Object.keys(completed).length}`})
              </Alert>
            )}
            <UserContext.Provider value={user}>
              <Outlet />
            </UserContext.Provider>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  );
}
