import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import ModeTypography from '../../../../../components/ModeTypography'

const Done = ({description}) => {
    const theme = useTheme()
  return (
    <Box>  <ModeTypography variant='h5' sx={{
        fontWeight:"500",
        pb:2
    }}>
        You are all done!
    </ModeTypography>
    <ModeTypography>
        AutoKT has generated a description for your repository. You can always edit it on the repository dashboard.
    </ModeTypography>
    <ModeTypography sx={{
        py:2,
        px:1,
        mt:2,
        backgroundColor:theme.palette.background.default
    }}>
        {description.content}
    </ModeTypography>
</Box>
  )
}

export default Done
