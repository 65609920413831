import React, { useContext, useEffect, useState } from 'react'
import filesApi from '../../../../api/files'
import { useTheme } from '@emotion/react'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { Box, LinearProgress } from '@mui/material';
import { UserContext } from '../../../root';

const ShowCode = ({currentFile, repoName}) => {
    const [content, setContent] = useState("")
    const user = useContext(UserContext)
    const [loading,setLoading] = useState(false)
    const theme = useTheme()

    const githubIntegration  = user?.integrations?.find((integration)=>integration.type==="GITHUB")

    const fetchContent = async()=>{
        setLoading(true)
        try{
            const {data:{content}} = await filesApi.getGithubFile({
                filename: currentFile.name,
                full_repo_name: repoName,
                access_token:githubIntegration?.access_token
            })
            console.log(content)
            setContent(content)
        }catch(err){
            console.error(err)
        }finally{
            setLoading(false)
        }
    }
    useEffect(()=>{
        console.log("CURRENTFILE")
        console.log(currentFile)
        if(currentFile?.name && user?.username){
            if(currentFile.include){
                fetchContent()
            }
        }
    },[currentFile?.name, currentFile?.include, user?.username])

    if(loading || !user?.username){
        return <Box sx={{py:2}} ><LinearProgress /></Box>
    }
  return (
    <CodeEditor style={{
        maxHeight:"90vh",
        whiteSpace:"pre-wrap",
        fontSize:"1rem",
        overflowY:"scroll"
      }} value={content} disabled  data-color-mode={theme.palette.mode} language={currentFile?.name?.split(".").slice(-1)} />
  )
}

export default ShowCode
