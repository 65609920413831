import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material'
import React from 'react'

const Triggers = ({repo}) => {
    const theme = useTheme()
  return (
    <Box>
    <Typography variant='h5' sx={{
        fontWeight:"500",
        pb:2
    }}>
        Configure triggers
    </Typography>
    <Typography>
        Triggers are events that generate/update documentation for a file. Triggers are what allow AutoKT to hook into the development lifecycle.
        <br /> <br /><span style={{
            color: theme.palette.secondary.main,
            fontWeight:"600"
        }}>For example:</span> On pull_request_merge trigger generates or updates a new version for the documentation for each file changed in the PR.
    </Typography>


    <Typography marginTop={2}>Manual triggers</Typography>
    <FormGroup>
        <FormControlLabel
            control={
              <Checkbox checked={true} name="on_generate" />
            }
            label={<>
                <Typography variant='h6'>on_manual_generate <span style={{
                    color: theme.palette.primary.main
                }}>(default)</span>:</Typography>
          <Typography> When documentation is generated manually by user selecting the file and clicking on generate.</Typography>
          </>
            }
          />

    </FormGroup>

    <Typography marginTop={2}>Github triggers</Typography>
    <FormGroup>
        <FormControlLabel
            control={
              <Checkbox checked={true} name="on_generate" />
            }
            label={<>
                <Typography variant='h6'>on_pull_request_merge <span style={{
                    color: theme.palette.primary.main
                }}>(default)</span>:</Typography>
          <Typography> When a pull request is merged on main AutoKT generates or updates a new version for all the files added or modified in the PR.</Typography>
          </>
            }
          />

    </FormGroup>
    <Typography >Note: Additional triggers are currently in development and coming soon.</Typography>


    </Box>
  )
}

export default Triggers
