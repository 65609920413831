import React, { useContext, useEffect, useState } from "react";
import userApi from "../../../api/user";
import Loader from "../../../components/Loader";
import organisationApi from "../../../api/organisation";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ShowUsers from "./ShowUsers";
import InviteUser from "./InviteUser";
import ModeTypography from "../../../components/ModeTypography";
import ShowSentInvites from "./ShowSentInvites";
import ShowRequests from "./ShowRequests";
import ShowInvitesV2 from "../../Invites/ShowInvites/ShowInvitesV2";
import inviteApi from "../../../api/invite";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../root";

const TYPES_ARRAY = ["readers", "editors", "admins"];

const ShowOrganisation = () => {
  const user = useContext(UserContext);
  const [invites, setInvites] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [loadingUsers, setLoadingUsers] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [orgUsers, setOrgUsers] = useState([]);

  const [orgMembers, setOrgMembers] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const githubIntegration = user?.integrations?.find(
    (integration) => integration.type === "GITHUB"
  );

  const isAdmin = organisation?.type !== "User" && organisation?.admins?.indexOf(user?.id) !== -1;
  const newInvites = invites.reduce((aggr, curr) => aggr + (curr?.approved === "False" ? 1 : 0), 0);
  console.log(newInvites);
  console.log(organisation?.admins);

  // const fetchUser = async () => {
  //   setIsLoading(true);
  //   try {
  //     const { data } = await userApi.profile();
  //     console.log(data);
  //     setUser({ ...data });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const fetchOrganisations = async () => {
    setIsLoading(true);
    try {
      const { data } = await organisationApi.getOrganisation();
      console.log(data);
      setOrganisation({ ...data });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrgUsers = async () => {
    setLoadingUsers(true);
    try {
      const { data } = await organisationApi.getOrgUsers(TYPES_ARRAY[tabIndex]);
      console.log(data);
      setOrgUsers([...data]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchInvites = async () => {
    try {
      const { data } = await inviteApi.getInvites();
      console.log(data);
      setInvites([...data]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (user?.username && user?.org_id) {
      fetchOrganisations();
      fetchInvites();
    } else if (!user?.org_id) {
      navigate("/onboard");
    }
  }, [user?.username]);

  useEffect(() => {
    if (tabIndex < 3) {
      fetchOrgUsers();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (isOpen) {
      fetchOrgMembers();
    }
  }, [isOpen]);

  if (loading) {
    return <Loader />;
  }

  const fetchOrgMembers = async () => {
    if (githubIntegration) {
      try {
        const { data } = await organisationApi.getOrgMembers({
          name: organisation.name,
          access_token: githubIntegration["access_token"],
        });
        console.log(data);
        setOrgMembers([...data]);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <Box px={4} pt={2}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
        <ModeTypography variant="h5" color="primary.main" fontWeight={"bold"}>
          Team
        </ModeTypography>
        <Button sx={{ py: 1 }} variant="contained" onClick={() => navigate("/settings/teams")}>
          <SettingsIcon />
        </Button>
      </Box>

      <Badge
        sx={{
          mt: 2,
          mr: 4,
        }}
        badgeContent={organisation?.type}
        color="secondary"
      >
        <ModeTypography variant="h3">{organisation?.name}</ModeTypography>
      </Badge>

      {isAdmin && (
        <Button
          sx={{
            display: "block",
            ml: "auto",
          }}
          variant="contained"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Invite users
        </Button>
      )}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          sx={{
            position: "relative",
          }}
          onChange={(e, val) => setTabIndex(val)}
          aria-label="roles tab"
        >
          <Tab label="Readers" value={0} />
          <Tab label="Editors" value={1} />
          <Tab label="Admins" value={2} />
          {isAdmin && <Tab label="Requests" value={3} />}
          {isAdmin && <Tab label="Invites sent" value={4} />}
          <Tab
            sx={{
              position: "absolute",
              right: 0,
              borderRadius: "5px 5px 0 0",
            }}
            label={
              <Badge
                badgeContent={newInvites.toString()}
                color="secondary"
                sx={{
                  pt: 1,
                }}
              >
                Invites
              </Badge>
            }
            value={5}
          />
        </Tabs>
      </Box>

      {loadingUsers && <CircularProgress />}
      {!loadingUsers && tabIndex < 3 && <ShowUsers users={orgUsers} />}
      {tabIndex === 3 && <ShowRequests />}
      {tabIndex === 4 && <ShowSentInvites />}
      {tabIndex === 5 && <ShowInvitesV2 user={user} invites={invites} />}

      <InviteUser
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        orgMembers={orgMembers}
        org_name={organisation?.name}
        installation_id={organisation?.installation_id}
        username={user?.username}
      />
    </Box>
  );
};

export default ShowOrganisation;
