import React, { useEffect, useState } from "react";
import SingleMDEditor from "../../../components/SingleMDEditor";
import { Box, Button, LinearProgress } from "@mui/material";
import ReactDiffViewer from "react-diff-viewer";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import docsApi from "../../../../api/docs";
import LoadingEditor from "./LoadingEditor";
import CollapsibleAlert from "../../../../components/CollapsibleAlert";

const DocView = ({
  documentation,
  showCodeDiff,
  setShowCodeDiff,
  loadingEditor,
  isUserDoc,
  setDocumentation,
  preview,
  generating,
  handleGenerateDocumentation,
}) => {
  const theme = useTheme();
  const { filename, type } = useParams();
  const [mainDocumentation, setMainDocumentation] = useState("");

  const fetchMainDoc = async () => {
    const {
      data: { content },
    } = await docsApi.getDoc({
      filename,
      type: type.toUpperCase(),
    });

    setMainDocumentation(content);
  };

  useEffect(() => {
    if (filename) {
      fetchMainDoc();
    }
  }, [filename, type]);

  if (loadingEditor) {
    return <LoadingEditor />;
  }

  return (
    <>
      {(documentation || isUserDoc) && (
        <SingleMDEditor
          preview={preview}
          setValue={setDocumentation}
          value={documentation}
          showCodeDiff={showCodeDiff}
          data-step="doc-editor"
        />
      )}
      {showCodeDiff && (
        <ReactDiffViewer
          styles={{
            diffContainer: {
              display: "block",
              flexGrow: 1,
              overflowY: "auto",
              zIndex: 300,
              color: "black",
            },
          }}
          useDarkTheme={theme.palette.mode === "dark"}
          oldValue={mainDocumentation || ""}
          newValue={documentation}
          showDiffOnly
          splitView={true}
        />
      )}
      {!documentation && !isUserDoc && (
        <Box
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          {generating ? (
            <LinearProgress sx={{ width: 120 }} />
          ) : (
            <Button disabled={generating} variant="contained" onClick={handleGenerateDocumentation}>
              Generate doc from code
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default DocView;
