import React, { useEffect } from 'react'
import { a11yProps } from '../FileSidebar/FileSidebar'
import { Tab, Tabs } from '@mui/material'

const DocumentationTabs = ({view, setView, hasDocumentation, isUserDoc}) => {

  useEffect(()=>{
    if(isUserDoc){
      setView(1)
    }else{
      if(hasDocumentation){
        setView(1)
      }else{
        setView(0)
      }
    }

  },[isUserDoc, hasDocumentation])
  if(isUserDoc){
    return <Tabs value={view} onChange={(e, value)=>{
      console.log(value)
      setView(value)
    }} aria-label="view tabs">
      <Tab value={1} label="Documentation" {...a11yProps(0)} />

    </Tabs>

  }

  if(!isUserDoc && !hasDocumentation){
    return <Tabs value={view} onChange={(e, value)=>{
      console.log(value)
      setView(value)
    }} aria-label="view tabs">
      <Tab label="Code" value={0} {...a11yProps(1)} />

      <Tab label="Documentation" value={1} {...a11yProps(0)} />

    </Tabs>
  }



  return (
      <Tabs value={view} onChange={(e, value)=>{
                  console.log(value)
                  setView(value)
                }} aria-label="view tabs">
                  <Tab label="Documentation" value={1} {...a11yProps(0)} />
                    <Tab label="Code" value={0} {...a11yProps(1)} />

            </Tabs>

    )
}

export default DocumentationTabs
