import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import MemoryIcon from "@mui/icons-material/Memory";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useNavigate } from "react-router-dom";
import OnboardingStepper from "./OnboardingStepper";
import { UserContext } from "../root";
import { parseOnboardingStep } from "../../utils/onboarding";
import userApi from "../../api/user";
import requestApi from "../../api/requests";
import githubIntegrationApi from "../../api/github";
import { AddCircle } from "@mui/icons-material";

const OnboardingForm = () => {
  const theme = useTheme();
  const [user, setUser] = useState({});

  const [activeStep, completed] = parseOnboardingStep(user);
  const [installations, setInstallations] = useState([]);
  const [installationId, setInstallationId] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const githubIntegration = user?.integrations?.find(
    (integration) => integration.type === "GITHUB"
  );

  const fetchUser = async () => {
    setLoading(true);
    try {
      const { data } = await userApi.profile();
      console.log(data);
      setInstallationId(
        data?.integrations?.find((integration) => integration.type === "GITHUB").installation_id
      );
      setUser({ ...data });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchInstallations = async () => {
    console.log(user);
    console.log("githubIntegration");
    console.log(githubIntegration?.installation_id);
    if (githubIntegration) {
      console.log("ran");
      try {
        const { data } = await userApi.getInstallations({
          access_token: githubIntegration["access_token"],
        });

        setInstallations([...data]);
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user?.username) {
      fetchInstallations();
    }
  }, [user?.username]);

  const handleOrgSave = async () => {
    setLoading(true);
    if (
      installations.find((installation) => installationId === installation.id).target_type ===
      "User"
    ) {
      try {
        const response = await userApi.updateInstallationId({
          installation_id: installationId,
        });
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const response = await requestApi.sendRequest({
          installation_id: installationId,
        });

        console.log(response);
      } catch (err) {
        console.error(err);
      }
    }
    const { data } = await githubIntegrationApi.refreshToken();
    console.log(data);
    if (data["redirect_url"]) {
      window.location.replace(data["redirect_url"]);
    } else {
      window.location.reload();
    }
  };
  return (
    <Box
      width={"100%"}
      sx={{
        color: theme.palette.mode === "dark" ? "white" : "black",
      }}
    >
      <OnboardingStepper activeStep={activeStep} completed={completed} />
      {/* <Box margin={"auto"} paddingY={2} display={"flex"} flexDirection={"column"} width={"100%"} alignItems={"center"}>
            <img src='https://autokt.io/wp-content/uploads/2023/08/AutoKT_transparent.png' width={"150px"} />
            <Typography variant='h2' >Welcome to <span style={{
            }}>Auto<span style={{
                color: theme.palette.primary.main,
                fontWeight:"bold"
            }}>KT</span></span></Typography>

            <Typography variant='h5' fontWeight={"semi-bold"}>Let' get you started</Typography>
        </Box> */}

      <Grid container padding={2} direction={"column"} rowGap={4} justifyContent={"space-around"}>
        {activeStep === 0 && (
          <Grid
            item
            xs={3}
            sx={{
              borderRadius: "15px",
              padding: 2,
              margin: "auto",
              minWidth: "300px",
              width: "65%",
            }}
          >
            <Typography variant="h3" display={"flex"} alignItems={"center"} marginBottom={4}>
              <GitHubIcon
                sx={{
                  fontSize: "3rem",
                  mr: 1,
                }}
              />{" "}
              Step 1: Authorize Github
            </Typography>

            <Typography variant="body1">
              Visit this{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt"
              >
                link
              </a>{" "}
              or click on the button below to authorize github. You will be redirected to the login
              page. Use your AutoKT credentials to log in to link the authorization with your user.
            </Typography>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
              <Button
                color="primary"
                sx={{ my: 2, mx: "auto", fontSize: "1.3rem" }}
                variant="contained"
                target="_blank"
                href="https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt"
              >
                Authorize github
              </Button>
              <img src="/redirect.png" width={"100%"} />
            </Box>
          </Grid>
        )}
        {activeStep === 1 && (
          <Grid
            item
            xs={3}
            sx={{
              borderRadius: "15px",
              padding: 2,
              margin: "auto",
              minWidth: "300px",
              width: "65%",
            }}
          >
            <Typography variant="h3" display={"flex"} alignItems={"flex-start"} marginBottom={4}>
              <GitHubIcon
                sx={{
                  fontSize: "3rem",
                  mr: 1,
                }}
              />{" "}
              Step 2: Install Github App
            </Typography>

            <Typography>
              You can install the AutoKT Github App by clicking this{" "}
              <a target="_blank" rel="noreferrer" href="https://github.com/apps/autokt">
                link
              </a>{" "}
              or clicking on the install app button below.
              <br />
              <br />
              <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Installing the Github App allows AutoKT to integrate your repositories and respond
                to code changes in the repository to generate and update your documentation.
              </span>
            </Typography>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
              <Button
                color="primary"
                sx={{ my: 2 }}
                target="_blank"
                href="https://github.com/apps/autokt"
                variant="contained"
              >
                Install github app
              </Button>
            </Box>

            <Typography fontWeight={"bold"} mt={2} variant="body1" color={"secondary.main"}>
              Already have an installation for your organisation/user?
            </Typography>
            <Typography>
              If you already have an existing installation on your github account you can easily
              select the existing installation in the dropdown below.
              <br />
              <ul>
                <li>
                  For installation in a USER account your account will directly be linked to that
                  installation.{" "}
                </li>
                <li>
                  For installation in a ORGANISATION a request will be sent to the admin for that
                  organisation's AutoKT account. Once approved you will be able to access the org's
                  repositories and documentation.{" "}
                </li>
              </ul>
            </Typography>
            <FormControl variant="filled" sx={{ my: 1, width: "100%" }}>
              <InputLabel id="installation-select-label">Select existing installation</InputLabel>
              <Select
                labelId="installation-select-label"
                fullWidth
                value={installationId || ""}
                onChange={(e) => {
                  setInstallationId(e.target.value);
                }}
              >
                {installations?.map((installation) => (
                  <MenuItem
                    sx={{
                      mb: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={installation?.id}
                    value={installation?.id}
                  >
                    {" "}
                    {installation.account.login} {`(${installation?.target_type})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={handleOrgSave} disabled={loading} variant="outlined" sx={{ my: 2 }}>
              {installations.find((installation) => installation.id === installationId)
                ?.target_type === "User"
                ? "Save"
                : "Request"}
            </Button>

            <img src="/install.png" width={"100%"} />
          </Grid>
        )}
        {activeStep === 2 && (
          <Grid
            item
            xs={3}
            sx={{
              borderRadius: "15px",
              padding: 2,
              margin: "auto",
              minWidth: "300px",
              width: "65%",
            }}
          >
            <Typography variant="h3" display={"flex"} alignItems={"center"} marginBottom={4}>
              You are all done!
            </Typography>

            <Typography variant="body1">
              Thank you for completing your onboarding! You can now unlock the full power of AutoKT
              and forget your documentation worries. <br /> Get started by adding a repository.
            </Typography>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
              <Button
                startIcon={<AddCircle />}
                color="secondary"
                sx={{ my: 2, mx: "auto", fontSize: "1.3rem" }}
                variant="contained"
                onClick={() => navigate("/repositories/add")}
              >
                Add repository
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OnboardingForm;
