import { useTheme } from "@emotion/react";
import { Box, Button, Divider, Input, OutlinedInput, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import userApi from "../api/user";
import { useNavigate } from "react-router-dom";
import { GitHub } from "@mui/icons-material";

const Signup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [company, setCompanyName] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSignup = async () => {
    console.log("access_token");
    setLoading(true);
    try {
      const { data } = await userApi.signup({
        username,
        name,
        org_name: company,
        job_role: jobRole,
        team_size: teamSize,
        password,
      });
      if (!data?.error) {
        navigate("/verify-email?signup=" + true);
      } else {
        console.log(data?.error);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: "90%",
          aspectRatio: "1",
          maxWidth: "620px",
          minWidth: "400px",
          display: "flex",
          padding: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box width={"70%"}>
          <Typography variant="h3" fontWeight={"600"} marginBottom={4}>
            Welcome to Auto
            <span
              style={{
                color: theme.palette.primary.main,
              }}
            >
              KT
            </span>
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{
              background: "#1f1f1f",
              color: "#fff",
              borderRadius: "5px",
              px: 2,
              py: 1,
            }}
            href="https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt&redirect_uri=https://autokt-backend.onrender.com/users/github-login"
            startIcon={<GitHub />}
          >
            Signup with Github
          </Button>
          <Divider
            sx={{
              my: 1.5,
            }}
          >
            Or
          </Divider>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <OutlinedInput
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              fullWidth
              sx={{
                marginBottom: "5px",
                marginRight: "5px",
              }}
              placeholder="Name"
            />
            <OutlinedInput
              value={company}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              fullWidth
              sx={{
                marginBottom: "5px",
              }}
              placeholder="Company name"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <OutlinedInput
              value={jobRole}
              onChange={(e) => {
                setJobRole(e.target.value);
              }}
              fullWidth
              sx={{
                marginBottom: "5px",
                marginRight: "5px",
              }}
              placeholder="Job role"
            />
            <OutlinedInput
              value={teamSize}
              type="number"
              onChange={(e) => {
                setTeamSize(e.target.value);
              }}
              fullWidth
              sx={{
                marginBottom: "5px",
              }}
              placeholder="Team size"
            />
          </Box>

          <OutlinedInput
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
            sx={{
              marginBottom: "5px",
            }}
            placeholder="Email"
          />
          <OutlinedInput
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            sx={{
              marginBottom: "15px",
            }}
          />

          <Button variant="contained" disabled={loading} onClick={handleSignup}>
            Signup
          </Button>
          <Typography mb={2}>
            Already have an account?{" "}
            <a
              href="/login"
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              Login.
            </a>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Signup;
