import axios from "axios";

const autoktAxios = axios.create();
export const GITHUB_INTEGRATION_URL = "https://autokt-github-integration.onrender.com";
// export const GITHUB_INTEGRATION_URL = "http://localhost:3030"
export const BASE_API_URL = "https://autokt-backend.onrender.com";
// export const BASE_API_URL = "http://127.0.0.1:8000";

autoktAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("USER_TOKEN");
    if (token) {
      config.headers = {
        Authorization: "Bearer " + token,
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

autoktAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.setItem("USER_TOKEN", "");
      window.location.reload();
    } else {
      Promise.reject(error);
    }
  }
);

export default autoktAxios;
