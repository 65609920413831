import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import Write from "../../Documentation/DocumentationEditor/WriteAiSidebar/Write/Write";
import { useCommands } from "@remirror/react";
import { ArrowBack, Close } from "@mui/icons-material";

const MENU_ITEMS = [
  {
    label: "Document your code",
  },
  {
    label: "Write with AI",
  },
];

const SlashMenu = forwardRef(
  (
    { menuPosition, activeSlashMenu, setActiveSlashMenu, value, setMenuVisible, isMenuVisible },
    ref
  ) => {
    const theme = useTheme();
    const menuQuery = value.split("/").slice(-1)[0];
    const commands = useCommands();

    const [focusedMenu, setFocusedMenu] = useState(0);

    useEffect(() => {
      const clearMenuHandler = (event) => {
        if (event.code === "Escape") {
          console.log("ran");
          console.log(activeSlashMenu);
          if (activeSlashMenu === -1) {
            setMenuVisible(false);
          } else {
            setActiveSlashMenu(-1);
          }
        }
        if (event.code === "ArrowUp") {
          if (focusedMenu > 0) {
            setFocusedMenu(focusedMenu - 1);
          }
        }
        if (event.code === "ArrowDown") {
          if (focusedMenu < MENU_ITEMS.length - 1) {
            setFocusedMenu(focusedMenu + 1);
          }
        }
        if (event.code === "Enter") {
          setActiveSlashMenu(focusedMenu);
        }
      };
      document.addEventListener("keydown", clearMenuHandler);
      return () => {
        document.removeEventListener("keydown", clearMenuHandler);
      };
    }, [activeSlashMenu, focusedMenu, isMenuVisible]);

    return (
      <Paper
        ref={ref}
        sx={{
          height: "280px",
          width: "300px",
          overflow: "auto",
          paddingX: 2,
          background: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
          position: "fixed",
          zIndex: 100,
          ...menuPosition,
        }}
      >
        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Button
            endIcon={activeSlashMenu !== -1 ? <ArrowBack /> : <Close />}
            onClick={() => {
              if (activeSlashMenu === -1) {
                setMenuVisible(false);
              } else {
                setActiveSlashMenu(-1);
              }
            }}
          >
            (Esc)
          </Button>
        </Box>
        {activeSlashMenu === 0 && (
          <Write documentation={value} setDocumentation={commands.insertMarkdown} />
        )}
        {activeSlashMenu === 1 && (
          <Write
            documentation={value}
            setDocumentation={commands.insertMarkdown}
            defaultTypeIndex={1}
          />
        )}

        {activeSlashMenu === -1 && (
          <List>
            {MENU_ITEMS.map((menuItem, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  sx={{
                    backgroundColor:
                      focusedMenu === index &&
                      (theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.08)"
                        : "rgba(0, 0, 0, 0.04)"),
                  }}
                  onClick={() => setActiveSlashMenu(index)}
                >
                  <ListItemText primary={menuItem.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    );
  }
);

export default SlashMenu;
