import { useTheme } from "@emotion/react";
import { Box, Button, Input, OutlinedInput, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import userApi from "../api/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GitHub } from "@mui/icons-material";
import Loader from "../components/Loader";

const GitHubIntegration = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [integration, setIntegration] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkingLoggedIn, setCheckingLoggedIn] = useState(true);
  searchParams.forEach((val, key) => console.log(val + "=" + key));

  const theme = useTheme();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleIntegration = async () => {
    console.log("access_token");
    setLoading(true);
    try {
      const {
        data: { access_token },
      } = await userApi.login({
        username,
        password,
      });
      if (access_token) {
        const { data } = await userApi.addIntegration({
          integration,
        });
        console.log(access_token);
        localStorage.setItem("USER_TOKEN", access_token);
        navigate("/onboard");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addIntegrationAndRedirect = async (integration) => {
    const { data } = await userApi.addIntegration({
      integration,
    });
    if (data) {
      navigate("/onboard");
    } else {
      setCheckingLoggedIn(false);
    }
  };

  useEffect(() => {
    const newIntegration = {};

    searchParams.forEach((val, key) => {
      newIntegration[key] = val;
    });
    setIntegration({ ...newIntegration });

    const isLoggedIn = localStorage.getItem("USER_TOKEN");
    if (isLoggedIn && !searchParams.get("autokt_access_token")) {
      addIntegrationAndRedirect(newIntegration);
    } else if (isLoggedIn) {
      navigate("/");
    } else if (searchParams.get("autokt_access_token")) {
      localStorage.setItem("USER_TOKEN", searchParams.get("autokt_access_token"));
      if (localStorage.getItem("EXTENSION_PORT")) {
        const port = localStorage.getItem("EXTENSION_PORT");
        localStorage.setItem("EXTENSION_PORT", "");

        window.location.assign(
          `http://localhost:${port}/auth/${searchParams.get("autokt_access_token")}`
        );
      } else {
        navigate("/");
      }
    } else {
      setCheckingLoggedIn(false);
    }
  }, []);

  if (checkingLoggedIn) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.default",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: "30%",
          aspectRatio: "1",
          minWidth: "400px",
          display: "flex",
          padding: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box width={"70%"}>
          <Typography variant="h4" fontWeight={"600"} marginBottom={4}>
            Login to Auto
            <span
              style={{
                color: theme.palette.primary.main,
              }}
            >
              KT
            </span>{" "}
            to complete{" "}
            {integration?.installation_id ? "Github App Integration" : "Github Authorization"}
            <GitHub />
          </Typography>
          <OutlinedInput
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
            sx={{
              marginBottom: "5px",
            }}
            placeholder="Email"
          />
          <OutlinedInput
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            sx={{
              marginBottom: "15px",
            }}
          />
          <Button disabled={loading} variant="contained" onClick={handleIntegration}>
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default GitHubIntegration;
