import React, { useEffect, useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';

const ShowRepo = ({repo, tabLevel, currentFile, setCurrentFile}) => {
    console.log(currentFile)
    const sortedRepoKeys = Object.keys(repo).sort((a,b)=>{
        const aDirectory = Object.keys(repo[a]).indexOf("include")==-1
        const bDirectory = Object.keys(repo[b]).indexOf("include")==-1

        if(!aDirectory&& !bDirectory){
            if(a[0]==="."){
                if(b[0]==="."){
                    return b.localeCompare(a)
                }else{
                    return 1
                }
            }else{
                if(b[0]==="."){
                    return -1
                }else{
                    return b.localeCompare(a)
                }
            }
        }

        if(aDirectory){
            if(bDirectory){
                return b.localeCompare(a)
            }else{
                return -1
            }
        }else{
            if(bDirectory){
                return 1
            }
        }
    })
    return (
        <div>
                        {sortedRepoKeys.map((keyLabel,index)=>{
                return <RepoTab key={index+keyLabel} label={keyLabel} tabLevel={tabLevel} currentFile={currentFile} setCurrentFile={setCurrentFile} repoTab={repo[keyLabel]} />
            })}

        </div>
    )
}

const RepoTab = ({label, repoTab, tabLevel,currentFile, setCurrentFile})=>{

    const {workspace} = useParams()

    const [isExpanded, setIsExpanded] = useState(workspace===label)
    const theme = useTheme()
    const isDarkMode = theme.palette.mode==="dark"
    const expandedBgColor = isDarkMode? "white":"black"
    const expandedTextColor = isDarkMode? "black": "white"

    const collapsedTextColor = isDarkMode? "white":"black"
    const collapsedBgColor = isDarkMode? "black":"white"
    const isDirectory = Object.keys(repoTab).indexOf("include")===-1

    const handleTabClick= (e)=>{
        if(!isDirectory && !isExpanded){
            setCurrentFile(label)
        }
        if(isDirectory){
            setIsExpanded(!isExpanded)
        }

    }

    useEffect(()=>{
        if(currentFile){
            console.log(label)

            if(isDirectory){

                if(currentFile.includes(label)){
                    setIsExpanded(true)
                }else{
                    setIsExpanded(false)
                }
            }

        }

    },[currentFile?.name])



    return <div style={{

        minWidth:"100%",
        width:"fit-content"
    }}>

        <p onClick={handleTabClick} style={{
        backgroundColor: (isExpanded || (!isDirectory && currentFile===label))? expandedBgColor: collapsedBgColor,
            color:(isExpanded || (!isDirectory && currentFile===label)) ? expandedTextColor: collapsedTextColor,
            padding:"5px",
            paddingLeft: tabLevel*20 +"px",
            cursor:"pointer",
            display:"flex",
            borderBottom: isDarkMode?"1px solid rgba(200,200,200,0.4)":  "1px solid rgba(20,20,20,0.2)",
            alignItems:"center"
        }}>   {isDirectory && (isExpanded? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> )} {label.split("/").slice(-1)}</p>
        {isExpanded && (isDirectory && <ShowRepo repo={repoTab} tabLevel={tabLevel+1} editable={false} currentFile={currentFile} setCurrentFile={setCurrentFile} /> )}
    </div>
}
export default ShowRepo
