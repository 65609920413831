import React, { useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { UserContext } from "../root";
import { Box, Divider, FormGroup, Input, Paper, Typography } from "@mui/material";
import ModeTypography from "../../components/ModeTypography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import organisationApi from "../../api/organisation";
import LaunchIcon from "@mui/icons-material/Launch";
import { getUserRole } from "../../utils/userRole";
import { useTheme } from "@emotion/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
const Settings = () => {
  const user = useContext(UserContext);
  const [org, setOrg] = useState({});
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchOrganisation = async () => {
    try {
      if (user?.org_id) {
        const { data } = await organisationApi.getOrganisation();
        console.log(data);
        setOrg({ ...data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const userRole = getUserRole(org, user?.id);

  useEffect(() => {
    fetchOrganisation();
  }, []);

  if (!user?.username) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box flexGrow={1} display={"flex"} alignItems={"stretch"} width={"100%"}>
        <Paper
          elevation={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ width: "20%", px: 2, py: 1, borderRadius: "0" }}
        >
          <ModeTypography variant="h4">{user?.name || user?.username}</ModeTypography>
          <Divider color={theme.palette.primary.main} sx={{ my: 1 }} />
          <Box
            onClick={() => navigate("/settings")}
            display={"flex"}
            alignItems={"center"}
            sx={{
              cursor: "pointer",
              borderRadius: "8px",
              py: 1,
              px: 2,
              backgroundColor:
                location.pathname === "/settings" ? "primary.main" : theme.palette.background.paper,
              color: location.pathname === "/settings" ? "#fff" : theme.palette.text,
            }}
          >
            <AccountCircleIcon sx={{ mr: 2 }} /> Basic
          </Box>
          <Box
            onClick={() => navigate("/settings/teams")}
            display={"flex"}
            alignItems={"center"}
            sx={{
              cursor: "pointer",
              borderRadius: "8px",
              py: 1,
              px: 2,
              backgroundColor:
                location.pathname === "/settings/teams"
                  ? "primary.main"
                  : theme.palette.background.paper,
              color: location.pathname === "/settings/teams" ? "#fff" : theme.palette.text,
            }}
          >
            <GroupWorkIcon sx={{ mr: 2 }} /> Teams
          </Box>
        </Paper>

        {location.pathname === "/settings" && (
          <BasicSettings user={user} org={org} userRole={userRole} />
        )}
        {location.pathname !== "/settings" && <Outlet />}
      </Box>
    </Box>
  );
};

const BasicSettings = ({ user, userRole, org }) => {
  return (
    <Box flexGrow={1} py={5} px={10}>
      <FormGroup sx={{ px: 2 }}>
        <ModeTypography variant="h4">Profile</ModeTypography>
        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography>Name</ModeTypography>
          <Input value={user?.name} />
        </Box>

        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography mr={2}>Email</ModeTypography>
          <Input value={user?.username} disabled />
        </Box>

        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography mr={2}>Job role</ModeTypography>
          <Input value={user?.job_role} disabled />
        </Box>
      </FormGroup>
      <Divider sx={{ my: 4 }} />
      <FormGroup sx={{ px: 2 }}>
        <ModeTypography variant="h4">Team</ModeTypography>
        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography>
            Team name
            <a href={org?.url} target="_blank" rel="noreferrer">
              <LaunchIcon />
            </a>
          </ModeTypography>
          <Input value={org?.name} />
        </Box>

        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography mr={2}>Role</ModeTypography>
          <Input value={userRole} disabled />
        </Box>

        <Box
          display="flex"
          width={"50%"}
          maxWidth={"450px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <ModeTypography mr={2}>Type</ModeTypography>
          <Input value={org?.type} disabled />
        </Box>
      </FormGroup>
    </Box>
  );
};

export default Settings;
