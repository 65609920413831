import axios from "axios"
import autoktAxios, { BASE_API_URL } from "./axios_autokt"
import githubAxios, { GITHUB_INTEGRATION_URL } from "./axios_github"


const sendInvite = (payload)=>autoktAxios.post(`${BASE_API_URL}/invites`, {...payload, approved: false})
const getInvites = ()=>autoktAxios.get(`${BASE_API_URL}/invites`)
const acceptInvite  = (payload)=> autoktAxios.post(`${BASE_API_URL}/invites/accept`, payload)
const getSentInvites = ()=>autoktAxios.get(`${BASE_API_URL}/invites/sent`)

const inviteApi = {
  sendInvite,
  getInvites,
  getSentInvites,
  acceptInvite
}

export default inviteApi
