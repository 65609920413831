import axios from "axios";
import githubIntegrationApi from "./github";

const githubAxios = axios.create();
// export const GITHUB_INTEGRATION_URL = "http://localhost:3030"
export const GITHUB_INTEGRATION_URL = "https://autokt-github-integration.onrender.com";
// export const BASE_API_URL = "http://127.0.0.1:8000";
export const BASE_API_URL = "https://autokt-backend.onrender.com";

githubAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("USER_TOKEN");
    if (token) {
      config.headers = {
        Authorization: "Bearer " + token,
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

githubAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      const { data } = await githubIntegrationApi.refreshToken();
      console.log(data);
      if (data["redirect_url"]) {
        window.location.replace(data["redirect_url"]);
      } else {
        window.location.reload();
      }
    } else {
      Promise.reject(error);
    }
  }
);

export default githubAxios;
