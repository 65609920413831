import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { Add } from "@mui/icons-material";

const ACTIONS = [{ icon: <Add />, name: "Create doc" }];

export default function AddOptions({ handleCreateDoc }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionClick = (actionName) => {
    if (actionName === "Create doc") {
      handleCreateDoc();
    }
  };
  return (
    <>
      <SpeedDial
        ariaLabel="AutoKT Options"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        clos
        open={open}
      >
        {ACTIONS.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipOpen
            onClick={() => handleActionClick(action.name)}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </>
  );
}
