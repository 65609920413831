import React, { useEffect, useState } from 'react'
import inviteApi from '../../../api/invite'
import { Box, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import ModeTypography from '../../../components/ModeTypography'
import Loader from '../../../components/Loader'
import userApi from '../../../api/user'

const ShowSentInvites = () => {
  const [invites, setInvites] = useState([])
  const [user,setUser] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchUser = async ()=>{
    try{
      const {data} = await userApi.profile()
      console.log(data)
      setUser({...data})
    }catch(err){
      console.log(err.response.status)
      if(err.response.status===401){
        localStorage.setItem("USER_TOKEN","")
      }
    }
  }

  const fetchSentInvites = async ()=>{
    setLoading(true)
    try{
      const {data} = await inviteApi.getSentInvites();
      console.log(data)
      setInvites([...data])
    }catch(err){
      console.error(err)
    }finally{
      setLoading(false)
    }
  }


  useEffect(()=>{
    fetchUser()
  },[])


  useEffect(()=>{
    fetchSentInvites()
  },[])



  if(loading){
    return <Loader />
  }

  return (
    <Box px={4} pt={2}>

      {invites.length===0 && <ModeTypography>No pending invites.</ModeTypography>}
      {invites.length>0 &&   <TableContainer sx={{width: "70%", margin:"auto"}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Invitee Email</TableCell>
            <TableCell >Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invites.map((invite) => (
            <TableRow
              key={invite['_id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell >{invite.from_user_email}</TableCell>
              <TableCell >{invite.role}</TableCell>
              <TableCell>{invite.approved? "Accepted":"Pending"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
  )
}

export default ShowSentInvites
