import autoktAxios, { BASE_API_URL, GITHUB_INTEGRATION_URL } from "./axios_autokt"
import githubAxios from "./axios_github"


const listRepos = (payload)=>githubAxios.post(`${GITHUB_INTEGRATION_URL}/repositories`,payload,{
    headers:{
        "Content-Type":"application/json"
    }
})


const mapRepo = (payload)=>autoktAxios.post(`${BASE_API_URL}/github/map-repo`,payload)

const listUserRepos = ()=>autoktAxios.get(`${BASE_API_URL}/github/repositories`)

const getRepo = (fullRepoName)=>autoktAxios.get(`${BASE_API_URL}/github/repositories/show?full_repo_name=${fullRepoName}`)


const editRepoDescription = (payload)=>autoktAxios.put(`${BASE_API_URL}/github/repositories/description`,payload)

const refreshToken = ()=>autoktAxios.post(`${BASE_API_URL}/github/refresh-token`)



const githubIntegrationApi = {
    listRepos,
    mapRepo,
    listUserRepos,
    editRepoDescription,
    getRepo,
    refreshToken
}

export default githubIntegrationApi