import React, { useEffect, useState } from "react";
import docsApi from "../../../api/docs";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Box, Button, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import Loader from "../../../components/Loader";
import ModeTypography from "../../../components/ModeTypography";
import NoWorkspaceDoc from "./NoWorkspaceDoc";
import { parseDocType, parseFilename } from "../../../utils/constants";
import MDEditor from "@uiw/react-md-editor";
import { useTheme } from "@emotion/react";

export const DOC_TYPE = { 3: "GENERATED", 2: "MANUAL" };

const ShowWorkspace = () => {
  const { workspace, type } = useParams();
  const [workspaceId, setWorkspaceId] = useState("");
  const [workspaceDocs, setWorkspaceDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const [searchFile, setSearchFile] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();

  const filteredWorkspaceDocs = workspaceDocs?.filter((doc) =>
    parseFilename(doc.filename, doc.type).toLowerCase().includes(searchFile.toLowerCase())
  );

  const fetchWorkspaceId = async () => {
    try {
      const {
        data: { workspace_id },
      } = await docsApi.getWorkspaceId({
        name: workspace,
        type: type.toUpperCase(),
      });

      setWorkspaceId(workspace_id);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspaceDocs = async () => {
    try {
      const { data } = await docsApi.listDocs({
        workspace_id: workspaceId,
        ...(tabIndex > 1 && { type: DOC_TYPE[tabIndex] }),
      });
      console.log(data);
      setWorkspaceDocs([...data]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkspaceId();
  }, []);

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceDocs();
    }
  }, [workspaceId, tabIndex]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box px={2} py={1}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Box>
          <ModeTypography>{`${type[0].toUpperCase()}${type.slice(1)}`} </ModeTypography>
          <ModeTypography variant="h4">{`${workspace}'s workspace`}</ModeTypography>
        </Box>
        <Box>
          <Button variant="contained" onClick={() => navigate("create")}>
            Create doc
          </Button>
        </Box>
      </Box>
      <TextField
        value={searchFile || undefined}
        placeholder="Search docs"
        disabled={workspaceDocs.length === 0}
        onChange={(e) => setSearchFile(e.target.value)}
      />
      <Tabs
        value={tabIndex}
        onChange={(e, value) => {
          console.log(value);
          setTabIndex(value);
        }}
      >
        <Tab label="Recent" />
        <Tab label="All" />
        <Tab label="User created" />
        <Tab label="Generated" />
      </Tabs>
      <Box>
        {filteredWorkspaceDocs?.map((doc) => (
          <Paper
            onClick={() => {
              navigate(encodeURIComponent(doc?.filename));
            }}
            sx={{
              my: 4,
              px: 2,
              py: 4,
              width: "70%",
              position: "relative",
              mx: "auto",
              cursor: "pointer",
              wordBreak: "break-word",
            }}
            key={doc["_id"]}
          >
            <ModeTypography variant="h4">{parseFilename(doc?.filename, doc?.type)}</ModeTypography>
            <MDEditor.Markdown
              source={doc?.excerpt}
              style={{
                background: "transparent",
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                backgroundColor: doc?.type === "GENERATED" ? "primary.main" : "secondary.main",
                top: 0,
                right: 0,
                px: 1,
                py: 0.5,
                borderRadius: "15px",
                color: "white",
              }}
            >
              {parseDocType(doc?.type)}
            </Typography>
          </Paper>
        ))}
        {workspaceDocs?.length === 0 && <NoWorkspaceDoc />}
      </Box>
    </Box>
  );
};

export default ShowWorkspace;
