import { Box, Button, Divider, FormGroup, Input, MenuItem, Select, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ModeTypography from '../../components/ModeTypography'
import { UserContext } from '../root'
import userApi from '../../api/user'
import githubIntegrationApi from '../../api/github'
import requestApi from '../../api/requests'
const TeamSettings = () => {
  const user = useContext(UserContext)
  const [teams, setTeams] = useState([])
  const [installations, setInstallations] = useState([])
  const [installationId, setInstallationId] = useState("")

  const githubInstallation = user?.integrations.find((integration)=>integration.type==="GITHUB")
  const [userTeam, setUserTeam] = useState(githubInstallation?.installation_id)
  const filteredInstallations = installations.filter((installation)=>!teams.find((team)=> team?.installation_id===installation.id.toString()))

  const fetchTeams = async ()=>{
    try{
      const {data} = await userApi.getUserOrgs()

      console.log(data)
      setTeams([...data])
    }catch(err){
      console.log(err)
    }
  }


  const fetchInstallations = async()=>{
    console.log("githubIntegration")
    console.log(githubInstallation?.installation_id)
    if(githubInstallation?.installation_id){
      console.log("ran")
      try{
        const {data} = await userApi.getInstallations({
          access_token: githubInstallation['access_token']
        });

        setInstallations([...data])
      }catch(err){
        console.log(err)
      }
    }

  }


  const handleOrgSave = async()=>{
    try{
      const response = await userApi.updateInstallationId({
        installation_id: userTeam
      })
      console.log(response)
    }catch(err){
      console.error(err)
    }

    const {data} =  await githubIntegrationApi.refreshToken()
    console.log(data)
    if(data['redirect_url']){
      window.location.replace(data['redirect_url'])
    }else{
      window.location.reload()
    }
  }

  const handleOrgRequest = async ()=>{
      if(installations.find((installation)=>installationId===installation.id).target_type==="User"){
        try{
          const response = await userApi.updateInstallationId({
            installation_id: installationId
          })
          console.log(response)
      }catch(err){
        console.log(err)
      }
    }else{
      try{
        const response = await requestApi.sendRequest({
          installation_id: installationId

        })

        console.log(response)
      }catch(err){
        console.error(err)
      }
    }
    const {data} =  await githubIntegrationApi.refreshToken()
    console.log(data)
    if(data['redirect_url']){
      window.location.replace(data['redirect_url'])
    }else{
      window.location.reload()
    }

  }

  useEffect(()=>{
    if(user?.username){
      fetchTeams()
      fetchInstallations()
    }
  },[user?.username])

  return (
    <Box flexGrow={1} py={5} px={10}>
           <FormGroup sx={{px:2}} >
            <ModeTypography variant="h4">Teams</ModeTypography>
            <Box  display="flex" width={"50%"} maxWidth={"450px"} justifyContent={"space-between"} alignItems={"center"} py={1}>
                <Select onChange={(e)=>setUserTeam(e.target.value)} sx={{flexGrow:1, mr:2}} value={userTeam}>{teams.map((team)=><MenuItem key={team?.org_id} value={team?.installation_id}>{team?.name}</MenuItem>)}</Select>
                <Button onClick={handleOrgSave} color='greenCustom' variant='outlined' disabled={githubInstallation?.installation_id===userTeam}>Switch team</Button>
            </Box>
            {githubInstallation?.installation_id===userTeam && <ModeTypography><a href={teams.find((team)=>team.installation_id===userTeam)?.url} target='_blank' rel="noreferrer">Github installation settings</a></ModeTypography>}

            </FormGroup>

        <Divider sx={{my:4}}  />
            <FormGroup sx={{px:2}} >
            <ModeTypography variant="h4">Add team</ModeTypography>
            <Box  width={"50%"} maxWidth={"450px"} justifyContent={"space-between"} alignItems={"center"} py={1}>
                 <ModeTypography variant='caption'>You can see all available teams that you haven't joined on autokt that are linked with your github account below.</ModeTypography>

                <Select onChange={(e)=>setInstallationId(e.target.value)} sx={{display:"block", mr:2, mb:2}} value={installationId}>{filteredInstallations.map((installation)=><MenuItem key={installation?.id} value={installation?.id}>{installation?.account?.login}</MenuItem>)}</Select>
                {installationId && <Button variant='contained' color='secondary' onClick={handleOrgRequest}>{ installations.find((installation)=>installation.id===installationId)?.target_type==="User"? "Join team": "Send request"}</Button>}

                <Divider sx={{my:2}} />
                 <ModeTypography mb={2}>If you don't see your account/team in the above dropdowns visit this <a target='_blank' rel='noreferrer' href='https://github.com/apps/autokt'>link</a> or click on the install app button to install the autokt github app</ModeTypography>
                <a target='_blank' rel='noreferrer' href='https://github.com/apps/autokt'><Button color='primary'  variant='contained' >Install github app</Button></a>

            </Box>
            </FormGroup>
        </Box>
  )
}

export default TeamSettings
