import React, { useState } from 'react'
import ReactDiffViewer from 'react-diff-viewer';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';

const CodeDiff = ({oldVal, newVal, setCodeSwitch}) => {
    const [oldValue, setOldValue] = useState(oldVal)
    const [newValue, setNewValue] = useState(newVal)
    const theme = useTheme()
  return (
    <div style={{
     display:"flex",
     flexDirection:"column",

    }} >
     <CloseIcon sx={{
          color: "white"
     }} onClick={()=>setCodeSwitch(1)}/>
     {/* <div style={{
          width:"100%",
          display:"flex",
          flexBasis:"45%",
          alignItems:"stretch"
     }}>
          <MDEditor style={{
               flex:1          }} height={"320px"}  value={oldValue} onChange={(val)=>setOldValue(val)}/>
        <MDEditor style={{
               flex:1
          }} height={"320px"} value={newValue} onChange={(val)=> setNewValue(val) }/>

     </div> */}
   
       
        <ReactDiffViewer styles={{
          diffContainer:{
               display: "block",

               height:"80vh",
               overflowY:"scroll"
          }
        }} useDarkTheme={theme.palette.mode==="dark"} oldValue={oldValue} newValue={newValue} showDiffOnly splitView={true}/>
               

    </div>
  )
}

export default CodeDiff