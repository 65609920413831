import { createTheme } from "@mui/material";



const theme = createTheme({
    palette: {
        mode:"dark",
        primary: {
          main: "#2684FF",
          dark:"#1b7db3"
        },
        secondary: {
          main: "#ff2693",
          dark:"#b31b67"
        },
        accent:{
          main:"#ffe526"
        },
        background:{
          default:"#1c2737",
          paper:"#000"
        }

      },
      typography: {
        button: {
          textTransform: 'none'
        }
      }
  });

  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: "#2684FF",
              dark:"#1b7db3"
            },
            secondary: {
              main: "#ff2693",
              dark:"#b31b67"
            },
            accent:{
              main:"#ffe526",
              light:"#EDBE00"
            },
            background:{
              default:"#1c2737",
              paper:"#ffff"
            },
            greenCustom:{
              main:"#159F2F",
              dark:"#26FF4F"
            }
          }
        : {
            // palette values for dark mode
            primary: {
              main: "#2684FF",
              dark:"#1b7db3"
            },
            secondary: {
              main: "#ff2693",
              dark:"#b31b67"
            },
            accent:{
              main:"#ffe526",
              light:"#EDBE00"
            },
            background:{
              default:"#1c2737",
              paper:"#000"
            },
            greenCustom:{
              main:"#159F2F",
              dark:"#26FF4F"
            }
          }),
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });


  export default getDesignTokens
