

export const mapCodeTree = (repoCode, fileObj = {})=>{
    let fileObjPointer = fileObj

    const fileKeys = Object.keys(repoCode)

    for(let i=0; i< fileKeys.length;i++){
        const filePathArr = fileKeys[i].split("/")

        const isFile = repoCode[fileKeys[i]]['type'] ==="FILE"

        for(let j=1; j<filePathArr.length;j++){
            const pathKey = filePathArr.slice(0,j).join("/")
            if(!fileObjPointer[pathKey]){
                fileObjPointer[pathKey] = {}
            }

            fileObjPointer = fileObjPointer[pathKey]
        }

        if(isFile){
            fileObjPointer[fileKeys[i]] = repoCode[fileKeys[i]]
        }

        fileObjPointer = fileObj
    }

    return fileObj
}

