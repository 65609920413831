export const getUserRole = (org, userId)=>{
  if(org?.admins?.indexOf(userId)!==-1){
    return "Admin"
  }
  if(org?.editors?.indexOf(userId)!==-1){
    return "Editor"
  }
  if(org?.readers?.indexOf(userId)!==-1){
    return "Reader"
  }

  return "N/A"
}
