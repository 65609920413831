import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import docsApi from "../../../../../api/docs";
import { MODAL_STYLE } from "../../../../Organisation/ShowOrganisation/InviteUser";
import ModeTypography from "../../../../../components/ModeTypography";
import MDEditor from "@uiw/react-md-editor";
import { useTheme } from "@emotion/react";
import { ContentCopy, InfoOutlined, InfoRounded } from "@mui/icons-material";

const TYPES = [
  { value: "DOCUMENT_CODE", label: "Document your code" },
  { value: "ASK_AI", label: "Write with AI" },
];

const Write = ({ documentation, setDocumentation, defaultTypeIndex = 0 }) => {
  const [queryResponse, setQueryResponse] = useState("");
  const theme = useTheme();

  const [query, setQuery] = useState("");
  const [asking, setAsking] = useState(false);
  const [type, setType] = useState(TYPES[defaultTypeIndex].value);
  const [open, setIsOpen] = useState(false);
  const [docType, setDocType] = useState("USER_FACING");

  const [code, setCode] = useState("");
  const [filename, setFilename] = useState("");

  const handleQuery = async () => {
    setAsking(true);
    try {
      const {
        data: { content },
      } = await docsApi.generateDoc({
        query,
        type,
        documentation,
      });
      setDocumentation(documentation + "\n" + content);
    } catch (err) {
      console.error(err);
    } finally {
      setAsking(false);
    }
  };

  const handleDocument = async () => {
    setAsking(true);
    try {
      const {
        data: { content },
      } = await docsApi.generateDoc({
        query,
        type,
        doc_type: docType,
        filename,
        file_content: code,
      });
      setDocumentation(documentation + "\n" + content);
    } catch (err) {
      console.error(err);
    } finally {
      setAsking(false);
    }
  };

  return (
    <>
      <Select
        sx={{ mb: 2 }}
        fullWidth
        value={TYPES.find((typeNew) => typeNew.value === type).value}
        placeholder="Please select AI action"
        onChange={(e) => {
          console.log(e.target.value);
          setType(e.target.value);
        }}
      >
        {TYPES?.map((type, index) => (
          <MenuItem
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
            key={index}
            value={type.value}
          >
            {" "}
            {type.label}{" "}
          </MenuItem>
        ))}
      </Select>

      {type === TYPES[0].value && (
        <>
          <TextField
            sx={{ mb: 1 }}
            fullWidth
            value={filename || undefined}
            onChange={(e) => setFilename(e.target.value)}
            placeholder="Filename of code to document"
          />
          <FormControlLabel
            sx={{ display: "flex" }}
            control={<Switch defaultChecked />}
            label={
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                User facing{" "}
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="body2">
                      User facing docs are more focused on usage while System docs are more focused
                      on explaination of the code.
                    </Typography>
                  }
                >
                  <InfoOutlined sx={{ ml: 1, fontSize: "1rem", cursor: "pointer" }} />
                </Tooltip>
              </Typography>
            }
            onChange={(_, checked) => {
              if (checked) {
                setDocType("USER_FACING");
              } else {
                setDocType("INTERNAL");
              }
            }}
          />

          {code ? (
            <>
              {" "}
              <Button
                variant="outlined"
                disabled={asking}
                color="primary"
                onClick={() => setIsOpen(true)}
              >
                Edit code
              </Button>{" "}
              <Button
                variant="outlined"
                disabled={asking}
                color="secondary"
                onClick={handleDocument}
              >
                Document code
              </Button>
            </>
          ) : (
            <Button disabled={!filename} variant="outlined" onClick={() => setIsOpen(true)}>
              + Add code
            </Button>
          )}
          <Modal
            open={open}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={MODAL_STYLE}>
              <ModeTypography id="modal-modal-title" variant="h6" component="h2">
                Enter code:
              </ModeTypography>
              <TextareaAutosize
                maxRows={30}
                minRows={30}
                sx={{ height: "420px", maxHeight: "420px", oveflowY: "scroll" }}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />

              <Button onClick={() => setIsOpen(false)}>Save</Button>
            </Box>
          </Modal>
        </>
      )}
      {type === TYPES[1].value && (
        <>
          {" "}
          <Typography>What do you want AutoKT to write?</Typography>
          <TextField
            sx={{ mb: 1 }}
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button disabled={asking} color="secondary" variant="outlined" onClick={handleQuery}>
            Ask
          </Button>
        </>
      )}
    </>
  );
};

export default Write;
