import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ModeTypography from '../../../components/ModeTypography';
import inviteApi from '../../../api/invite';
export const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display:"flex",
  flexDirection:"column"
};

const ROLES = [{
  value:"readers",
  label:"Reader"
},{
  value:"editors",
  label:"Editor"
},{
  value:"admins",
  label:"Admin"
},]


const InviteUser = ({isOpen, setIsOpen, orgMembers, org_name, username, installation_id}) => {
  const [inviteEmail, setInviteEmail] = useState("")
  const [memberValue, setMemberValue] = useState("")
  const [role, setRole] = useState("")
  const member =  orgMembers.find((member)=>member.login===memberValue) || undefined

  useEffect(()=>{
    if(memberValue){
      console.log("ran")
      setInviteEmail(member?.email || '')
    }
  },[memberValue])

  const handleSendInvite = async ()=>{
    const payload = {
      org_name,
      to_user_email: inviteEmail,
      from_user_email: username,
      installation_id,
      role
    }
    console.log(payload)
    if(Object.values(payload).reduce((agr,curr)=> curr? true: false,false)){
      try{
        const {data} = await inviteApi.sendInvite(payload)
        if(data?.success){
          setIsOpen(false)
        }
      }catch(err){
        console.error(err)
      }
    }

  }

  return (
    <Modal
    open={isOpen}
    onClose={()=>setIsOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={MODAL_STYLE}  >
      <ModeTypography id="modal-modal-title" variant="h6" component="h2">
        Invite users
      </ModeTypography>
      {(member && !member?.email )&& <ModeTypography color="secondary.light" sx={{
        px:1,
        my:2,
      }}>Member's email is not present.</ModeTypography>}
      <TextField sx={{
        my:2
      }} onChange={(e)=>setInviteEmail(e.target.value)} value={inviteEmail} label="User email" variant="outlined"/>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
      <InputLabel id="demo-simple-select-standard-label">Select user label</InputLabel>

      <Select
        labelId="demo-simple-select-standard-label"
        value={role}
        onChange={(e)=>{
        console.log(e.target.value)
        setRole(e.target.value)
      }}>
        {ROLES?.map((role)=><MenuItem value={role.value}>{role.label}</MenuItem>)}

      </Select>
      </FormControl>
      <Divider variant='middle' sx={{
        my:2
      }} />
      <Select
        value={memberValue}
      onChange={(e)=>{
        console.log(e.target.value)
        setMemberValue(e.target.value)
      }}>
        {orgMembers?.map((member)=><MenuItem value={member?.login}>{member?.name|| member?.login}</MenuItem>)}

      </Select>
      <Button variant='contained' onClick={handleSendInvite} sx={{mt:2}}>Invite</Button>
    </Box>
  </Modal>
  )
}

export default InviteUser
