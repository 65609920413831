import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Write from "./Write/Write";
import Ask from "./Ask/Ask";

const WriteAiSidebar = ({ documentation, setDocumentation, handleSetAiRelatedDoc }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState("default");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: "30%", pt: 2, overflowY: "auto" }} height={"calc(100vh - 86px)"}>
      <Box width={"95%"} margin={"auto"}>
        <Accordion
          expanded
          onChange={handleChange("ask")}
          sx={{
            background: theme.palette.mode === "dark" ? "#1f1f1f" : "white",
          }}
          data-step="ask-autokt"
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Ask AutoKT (BETA)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Ask documentation={documentation} handleSetAiRelatedDoc={handleSetAiRelatedDoc} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default WriteAiSidebar;
