import { useEffect, useState, version } from "react";
import ShowRepo from "./components/ShowRepo";
import githubIntegrationApi from "../../../api/github";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowDoc from "./ShowDoc";
import engineApi from "../../../api/engine";
import { ArrowCircleLeft, Download } from "@mui/icons-material";
import { mapCodeTree } from "../../../utils/repoCode";
import CodeDiff from "../CodeDiff";
import ShowCode from "./components/ShowCode";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadDocumentation } from "../../../utils/download";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function EditDocs() {
  const [currentFile, setCurrentFile] = useState({});
  const [repo, setRepo] = useState({});
  const [documentations, setDocumentations] = useState({});
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [repoFlattened, setRepoFlattened] = useState({});

  const [selectedDocVersion, setSelectedDocVersion] = useState("");
  const [loading, setLoading] = useState(true);
  const [codeSwitch, setCodeSwitch] = useState(0);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();

  const full_repo_name = searchParams.get("full_repo_name");
  const { search, pathname } = useLocation();
  const currentDocumentation =
    documentations[currentFile?.name]?.find((doc) => {
      const versionValue = selectedDocVersion ? selectedDocVersion : "main";
      return doc.version === versionValue;
    })?.documentation || "";

  const fetchRepo = async () => {
    setLoading(true);
    try {
      const { data } = await githubIntegrationApi.getRepo(full_repo_name);
      setRepoFlattened({ ...data["repo_code"] });
      const repoData = mapCodeTree(data["repo_code"]);
      console.log("data", data);
      setRepo({ ...data, repo_code: { ...repoData } });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchRepoVersion = async () => {
    setLoading(true);
    try {
      const { data } = await engineApi.listDocs({ full_repo_name, version: selectedVersion });
      setRepoFlattened({ ...data["code_tree"] });
      const repoData = mapCodeTree(data["code_tree"]);
      setRepo({ ...data, repo_code: { ...repoData } });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchVersions = async () => {
    try {
      const { data } = await engineApi.getVersions(full_repo_name);
      setVersions([...data, { name: "main", repo_name: full_repo_name }, { name: "All" }]);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDocVersions = async () => {
    try {
      const { data } = await engineApi.getDocVersions({
        full_repo_name,
        file_path: currentFile.name,
      });
      if (data?.versions) {
        const versions = data.versions.map((version) => ({ version, documentation: "" }));
        setDocumentations({ ...documentations, [currentFile.name]: versions });

        if (selectedVersion) {
          setSelectedDocVersion(
            data.versions.find(
              (version) => version.split("/").slice(-1)[0].split(".")[0] === selectedVersion
            )
          );
        } else {
          setSelectedDocVersion(
            data.versions.find((version) => version.split("/").slice(-1)[0] === "main.md")
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateDocumentation = async () => {
    setSaving(true);
    console.log(currentFile?.name);
    try {
      const response = await engineApi.updateDocumentation({
        full_repo_name,
        file_path: currentFile.name,
        documentation: "",
      });
      console.log(response);
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  // TODO: Update
  const handleDocUpdate = (val) => {
    const newDocumentations = documentations[currentFile.name].map((doc) => {
      const versionValue = selectedDocVersion ? selectedDocVersion : "main";
      if (doc.version === versionValue) {
        doc.documentation = val;
      }
      return doc;
    });
    console.log(newDocumentations);
    setDocumentations({ ...documentations, [currentFile.name]: [...newDocumentations] });
    setCurrentFile({ ...currentFile, documentation: val });
  };

  const handleViewDiff = async () => {
    try {
      const selectedDocVersionName = selectedDocVersion.split("/").slice(-1)[0].slice(0, -3);
      const selectedDoc = documentations[currentFile.name].find(
        (doc) => doc.version === selectedDocVersion
      );

      if (selectedDoc.documentation === "") {
        const {
          data: { documentation },
        } = await engineApi.getDocumentation({
          full_repo_name,
          file_path: currentFile.name,
          version: selectedDocVersionName,
        });
        const newDocumentation = documentations[currentFile.name].map((doc) => {
          if (doc.version === selectedDocVersion) {
            doc.documentation = documentation;
          }

          return doc;
        });

        setDocumentations({ ...documentations, [currentFile.name]: [...newDocumentation] });
      }

      const mainDoc = documentations[currentFile.name].find(
        (doc) => doc.version.split("/").slice(-1)[0].slice(0, -3) === "main"
      );

      if (mainDoc.documentation === "") {
        const {
          data: { documentation },
        } = await engineApi.getDocumentation({
          full_repo_name,
          file_path: currentFile.name,
          version: "main",
        });
        const newDocumentation = documentations[currentFile.name].map((doc) => {
          if (doc.version.split("/").slice(-1)[0].slice(0, -3) === "main") {
            doc.documentation = documentation;
          }

          return doc;
        });

        console.log(newDocumentation);

        setDocumentations({ ...documentations, [currentFile.name]: [...newDocumentation] });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCodeSwitch(2);
    }
  };

  useEffect(() => {
    fetchRepo();
  }, []);

  useEffect(() => {
    fetchVersions();
  }, []);

  useEffect(() => {
    console.log("searched");
    if (Object.keys(repoFlattened).length > 0) {
      if (searchParams.get("filename")) {
        const filename = searchParams.get("filename");
        console.log(Object.keys(repoFlattened));
        if (Object.keys(repoFlattened).indexOf(filename) !== -1) {
          console.log("FILENAME FOUND");
          setCurrentFile({
            ...currentFile,
            name: filename,
            include: repoFlattened[filename].include,
          });
        }
      }
    }
  }, [search, Object.keys(repoFlattened).length]);

  useEffect(() => {
    if (currentFile?.name) {
      fetchDocVersions();
    }
  }, [currentFile?.name, selectedVersion]);

  useEffect(() => {
    if (selectedVersion) {
      if (selectedVersion === "All") {
        fetchRepo();
      } else {
        fetchRepoVersion();
      }
    }
  }, [selectedVersion]);

  if (loading) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: "50vh",
          left: "50vw",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        height: "auto",
        backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
      }}
    >
      <ArrowCircleLeft
        sx={{
          color: theme.palette.mode === "dark" ? "white" : "black",
          position: "absolute",
          right: 2,
        }}
        onClick={() => {
          navigate("/repositories");
        }}
      />
      <div
        style={{
          width: "30vw",
          height: "90vh",
          maxHeight: "90vh",
          overflowY: "scroll",
        }}
      >
        <Box>
          {versions.length > 0 && (
            <>
              {/* <InputLabel id="map-version-select-label">Documentation Version</InputLabel> */}
              <TextField
                value={selectedVersion}
                label="FILTER BY: Documentation Version"
                select
                fullWidth
                sx={{
                  mt: 1,
                }}
                onChange={(e) => {
                  setSelectedVersion(e.target.value);
                }}
              >
                {versions.length > 0 &&
                  versions.map((version) => {
                    return (
                      <MenuItem key={version.name} value={version.name}>
                        {version.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </>
          )}
          <Autocomplete
            sx={{
              mt: 2,
            }}
            disablePortal
            fullWidth
            options={Object.keys(repoFlattened)}
            value={currentFile?.name}
            onChange={(e, newVal) => {
              if (newVal && repoFlattened[newVal]?.include) {
                searchParams.set("filename", newVal);
                setSearchParams(searchParams);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Search file" />}
          />
        </Box>
        <ShowRepo
          repo={repo["repo_code"]}
          tabLevel={0}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 20px",
          width: "70vw",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? "white" : "black",
            mt: 1,
            display: "flex",
            alignItems: "flex-start",
          }}
          variant="h5"
        >
          {currentFile?.name || "Select a file to get started."}
          {currentFile?.name && currentDocumentation && (
            <Tooltip
              title="Download"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <DownloadIcon
                onClick={() => downloadDocumentation(currentDocumentation, currentFile?.name)}
                sx={{ fontSize: "2rem", cursor: "pointer", ml: 1, color: "secondary.main" }}
              />
            </Tooltip>
          )}
        </Typography>
        {currentFile?.name && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={codeSwitch}
                onChange={(e, value) => {
                  setCodeSwitch(value);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Code" {...a11yProps(0)} />
                <Tab label="Documentation" {...a11yProps(1)} />
              </Tabs>
              {codeSwitch === 1 && (
                <Box display={"flex"} marginBottom={1}>
                  {selectedDocVersion &&
                    selectedDocVersion?.split("/")?.slice(-1)[0] !== "main.md" && (
                      <Button onClick={handleViewDiff}>View Diff</Button>
                    )}

                  {selectedDocVersion && (
                    <>
                      <TextField
                        value={selectedDocVersion}
                        select
                        label="File Doc Version"
                        sx={{
                          minWidth: "200px",
                          width: "max-content",
                        }}
                        onChange={(e) => {
                          setSelectedDocVersion(e.target.value);
                        }}
                      >
                        {documentations[currentFile.name] &&
                          documentations[currentFile.name].map((doc) => {
                            return (
                              <MenuItem key={doc.version} value={doc.version}>
                                {doc.version.split("/").slice(-1)}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </>
                  )}
                  <Button disabled={saving} onClick={handleUpdateDocumentation}>
                    Update doc
                  </Button>
                </Box>
              )}
            </Box>

            {codeSwitch === 0 && <ShowCode currentFile={currentFile} repoName={full_repo_name} />}

            {codeSwitch === 1 && (
              <ShowDoc
                editable={true}
                version={selectedDocVersion ?? "main"}
                full_repo_name={full_repo_name}
                documentations={documentations}
                setDocumentations={setDocumentations}
                currentFile={currentFile}
                setCurrentFile={setCurrentFile}
                handleDocUpdate={handleDocUpdate}
              />
            )}
            {codeSwitch === 2 && (
              <CodeDiff
                setCodeSwitch={setCodeSwitch}
                oldVal={
                  documentations[currentFile.name]?.find(
                    (doc) => doc.version.split("/").slice(-1)[0] === "main.md"
                  ).documentation
                }
                newVal={
                  documentations[currentFile.name]?.find(
                    (doc) => doc.version === selectedDocVersion
                  ).documentation
                }
              />
            )}
          </>
        )}
      </div>
      <VisibilityIcon
        sx={{
          position: "absolute",
          p: 2,
          cursor: "pointer",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
          bottom: 4,
          right: 2,
        }}
        onClick={() => {
          const viewPath = pathname.replace("edit", "show");
          navigate(viewPath + search);
        }}
      />
    </div>
  );
}

export default EditDocs;
