import { AddCircle } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const WelcomeScreen = () => {
    const navigate = useNavigate()
    const theme = useTheme()
  return (
    <Box sx={{
        px:2,
        pt:4,
        width:"100%",
        height:"100vh",
        display:"flex",
        flexDirection:"column",
        color: theme.palette.mode=="dark"? "white": "black",

        alignItems:"center"
    }}>

        <Box sx={{
            width:"50%",
            display:"flex",
            alignItems:"center",
            flexDirection:"column"
        }}>
            <Typography sx={{
                fontSize:"1.5rem"
            }}>Seems a little empty here.</Typography>
            <Typography sx={{
                fontSize:"2rem",
                textAlign:"center"
            }}> Click on the <AddCircle sx={{
                cursor:"pointer",
                fontSize:"3rem",
                position:"relative",
                top: 12,
                color:theme.palette.mode==="dark"? "secondary.main": "secondary.main",
            }}  onClick={()=>navigate('/repositories/add')}  /> button to add a repository and get started. </Typography>
            <img src='https://autokt.io/wp-content/uploads/2023/08/AutoKT_transparent.png' width={"350px"} />

        </Box>
    </Box>
  )
}

export default WelcomeScreen
