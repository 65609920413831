import { Typography } from "@mui/material";

export const DASHBOARD_STEPS = [
  {
    target: '[data-step="filename"]',
    content: (
      <>
        <Typography>Hey, welcome to AutoKT Doc Editor!</Typography>
        <Typography>
          Let's get started! This is the where you can name the file you are working on.
        </Typography>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-step="workspace"]',
    content: (
      <>
        <Typography>These are your workspaces</Typography>
        <Typography>
          Workspaces are of three types, User, Team and Repo. You are by default in the User
          workspace. To add team/repo workspaces complete your onboarding!
        </Typography>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-step="workspaces"]',
    content: (
      <>
        <Typography>This is where all your workspace docs are</Typography>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-step="doc-editor"]',
    content: (
      <>
        <Typography>The Editor supports all markdown syntax</Typography>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-step="write-autokt"]',
    content: (
      <>
        <Typography>Write with AutoKT</Typography>
        <Typography>
          Writing documentation is hard, AutoKT is here to help.
          <br />
          You can document your code or ask AutoKT to write as a technical writer.
        </Typography>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-step="ask-autokt"]',
    content: (
      <>
        <Typography>Ask AutoKT</Typography>
        <Typography>
          AutoKT searches through your documentation and intelligently answers your questions from
          your existing documentation.
        </Typography>
      </>
    ),
    disableBeacon: true,
  },
];
