import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

const steps = ['Authorize Github', 'Add Github App', 'You are all done!'];

export default function OnboardingStepper({activeStep, completed}) {

  return (
    <Box sx={{ width: '90%',mx:"auto", pt:2, }}>
      <Stepper  activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color={"inherit"}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
