import { Box, Button, Grid, Paper, Typography,useTheme } from '@mui/material'
import React, { useState } from 'react'
import Overview from './Steps/Overview'
import Triggers from './Steps/Triggers'
import Versioning from './Steps/Versioning'
import Done from './Steps/Done'
import githubIntegrationApi from '../../../../api/github'
import { useNavigate } from 'react-router-dom'

const STEPS = [
    {
    label:"Overview",
    component: <Overview />
},{
    label:"Versioning",
    component: <Versioning />
}, {
    label:"Triggers",
    component: <Triggers />
}, {
    label:"Done",
    component:<Done />
},]

const AddRepoForm = ({repo}) => {
    console.log(repo)
    const [selectedStep, setSelectedStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const navigate = useNavigate()
    const theme = useTheme()

    const handleNext = ()=>{
        if(selectedStep<STEPS.length-1){
            setSelectedStep((step)=>step+1)
        }
    }

    const handlePrev = ()=>{
        if(selectedStep>0 && selectedStep<STEPS.length-1){
            setSelectedStep((step)=>step-1)
        }
    }

    const handleDownload = async()=>{
        setLoading(true)
        try{
            const {data:{description}} = await githubIntegrationApi.mapRepo({full_repo_name:repo['full_name'],repo_id: repo.id })
            console.log(description)
            setDescription(description)
        }catch(err){
            console.error(err)
        }
        finally{
            setLoading(false)
            setSelectedStep(STEPS.length-1)
        }
    }

  return (
    <Box sx={{
        width:"100%",
        height:"90vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }}>
        <Paper elevation={4} sx={{
            width:"50%",
            minWidth:"480px",
            height:"fit-content",
            borderRadius:"15px",
            py:4,
            px:4,
            display:"flex",
            flexDirection:"column",
            alignItems:"stretch",

        }} >
            <Typography variant='h5'><span style={{
                color: theme.palette.secondary.main,
                fontWeight:"semi-bold"
            }}>Adding repository:</span> {repo['full_name']}</Typography>

            <Grid container sx={{
                flexGrow:1,
                padding:2,
            }} >
                <Grid item xs={4} sx={{
                    overflowY:"scroll",
                    alignItems:"center"
                }}>

                    {STEPS.map((step,index)=><Typography variant='body1' sx={{
                        p:2,
                        my:2,
                        mx:2,
                        backgroundColor: index===selectedStep && "secondary.main",
                        border: index<selectedStep && "1px inset "+theme.palette.secondary.main,
                        color:index===selectedStep && "#fff",
                        fontWeight:index===selectedStep && "600",
                    }} key={index}>{step.label}</Typography>)}
                </Grid>
                <Grid item xs={8} sx={{
                    borderRadius:"0px 5px 5px 0px",
                    padding:2,
                    backgroundColor: theme.palette.mode==="dark" ?"#000":"#fff",
                    overflowY:"scroll",
                    height:"520px",


                }}>{selectedStep >= STEPS.length-1? <Done description={description}/> :React.cloneElement(STEPS[selectedStep].component,{repo})}</Grid>
            </Grid>
            <Box sx={{
                width:"100%",
                display:"flex",
                justifyContent:"space-between",
                my:1
            }}>
                {selectedStep<STEPS.length-1 && <Button disabled={selectedStep<=0} onClick={handlePrev} color='secondary'>Previous</Button>}
                {selectedStep<STEPS.length-2 && <Button color='primary' onClick={handleNext} variant='contained'>Next</Button>}
                {selectedStep==STEPS.length-2 && <Button color='secondary' disabled={loading} onClick={handleDownload} variant='contained'>Add</Button>}
                {selectedStep==STEPS.length-1 && <Button color='secondary' disabled={loading} onClick={()=>{
                    navigate("/repositories")
                }} variant='contained'>Go to dashboard</Button>}
            </Box>
        </Paper>
    </Box>
  )
}

export default AddRepoForm
