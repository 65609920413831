import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <Box sx={{
      position:"fixed",
      top:"50vh",
      left:"50vw"
  }}><CircularProgress color='secondary' /></Box>
  )
}

export default Loader
