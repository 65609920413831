import { Autocomplete, Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import FileSidebar from "./FileSidebar/FileSidebar";
import githubIntegrationApi from "../../../api/github";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { mapCodeTree } from "../../../utils/repoCode";
import Loader from "../../../components/Loader";
import engineApi from "../../../api/engine";
import docsApi from "../../../api/docs";
import AddOptions from "./AddOptions/AddOptions";
import {
  checkCreateMode,
  getObjectSafely,
  handleCmdKeysGenerator,
  parseDocVersion,
  parseFilename,
  parseGeneratedFilename,
  parseGeneratedFilenameWithVersion,
  parseUserFilename,
} from "./utils";
import { downloadDocumentation } from "../../../utils/download";
import DocumentationTabs from "./DocumentationTabs/DocumentationTabs";
import DocView from "./DocView/DocView";
import organisationApi from "../../../api/organisation";
import { UserContext } from "../../root";
import ShowCode from "../../Dashboard/ShowRepo/components/ShowCode";
import { AutoFixHigh, Download, Save } from "@mui/icons-material";
import WriteAiSidebar from "./WriteAiSidebar/WriteAiSidebar";
import Joyride, { STATUS } from "react-joyride";
import { DASHBOARD_STEPS } from "../../../joyride/dashboard-steps";
import { useTheme } from "@emotion/react";

const DocumentationEditor = () => {
  const { type, workspace, filename } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isCreateMode = checkCreateMode(location.pathname);

  const [preview, setPreview] = useState(isCreateMode ? "live" : "preview");

  // Versioning
  const [repoVersions, setRepoVersions] = useState([]);
  const [docVersions, setDocVersions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const repoVersion = searchParams.get("repo_version") || "All";
  const docVersion = searchParams.get("doc_version") || "main";

  const [loading, setLoading] = useState(true);
  const [loadingEditor, setLoadingEditor] = useState(false);
  const [loadingFileSidebar, setLoadingFileSidebar] = useState(true);
  const [showAiWriter, setShowAiWriter] = useState(true);

  const [showCodeDiff, setShowCodeDiff] = useState(false);
  const [repo, setRepo] = useState({});
  const [userDir, setUserDir] = useState({});
  const [userWorkspaces, setUserWorkspaces] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [workspaceId, setWorkspaceId] = useState("");
  const [documentation, setDocumentation] = useState("");
  const [workspaceDocs, setWorkspaceDocs] = useState([]);
  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);

  const [docCodeView, setDocCodeView] = useState(0);
  const theme = useTheme();

  const [renamedFilename, setRenamedFilename] = useState("");

  const isUserDoc = useMemo(
    () =>
      checkCreateMode(location.pathname) ||
      workspaceDocs.find((doc) => doc.filename === filename)?.type === "MANUAL",
    [workspaceDocs, workspace, filename]
  );

  const allWorkspaces = [
    {
      ...user,
      type: "user",
      subtitle: "This is where all your personal documentation can be added.",
    },
    ...(Object.keys(organisation).length > 0 ? [organisation] : []),
    ...userWorkspaces,
  ];

  const fetchWorkspaceId = async () => {
    setLoadingFileSidebar(true);
    try {
      const {
        data: { workspace_id },
      } = await docsApi.getWorkspaceId({
        name: workspace,
        type: type.toUpperCase(),
      });

      setWorkspaceId(workspace_id);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingFileSidebar(false);
    }
  };

  const fetchWorkspaceDocs = async () => {
    setLoadingFileSidebar(true);
    try {
      const { data } = await docsApi.listDocs({
        workspace_id: workspaceId,
      });
      const userDocs = data.filter((doc) => doc.type === "MANUAL");
      const userDocRepo = userDocs.reduce((agr, curr) => {
        agr[curr?.filename] = { type: "FILE", include: true };
        return agr;
      }, {});

      setWorkspaceDocs([...data]);

      const userData = mapCodeTree(userDocRepo);

      setUserDir({ repo_code: { ...userData }, flattened: { ...userDocRepo } });
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingFileSidebar(false);
      setLoading(false);
    }
  };

  const fetchWorkspaces = async () => {
    setLoading(true);
    if (user?.org_id) {
      try {
        const { data } = await githubIntegrationApi.listUserRepos();
        setUserWorkspaces([...data.map((repo) => ({ ...repo, type: "repo" }))]);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const fetchOrganisations = async () => {
    setLoading(true);
    try {
      if (user?.org_id) {
        const { data } = await organisationApi.getOrganisation();
        setOrganisation({
          ...data,
          subtitle: "This is where your team level documentation can be added.",
          type: "team",
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchRepo = async () => {
    setLoadingFileSidebar(true);
    try {
      const { data } = await githubIntegrationApi.getRepo(workspace);

      const repoData = mapCodeTree(data["repo_code"]);
      const flattenedRepo = Object.keys(data["repo_code"])
        .filter((filename) => data["repo_code"][filename].type === "FILE")
        .reduce((agr, curr) => {
          agr[curr] = data["repo_code"][curr];
          return agr;
        }, {});
      setRepo({ ...data, repo_code: { ...repoData }, flattened: { ...flattenedRepo } });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setLoadingFileSidebar(false);
    }
  };

  const fetchRepoVersion = async () => {
    setLoadingEditor(true);
    try {
      const { data } = await engineApi.listDocs({
        full_repo_name: workspace,
        version: repoVersion,
      });
      const repoData = mapCodeTree(data["code_tree"]);
      setRepo({ ...data, repo_code: { ...repoData }, flattened: { ...data["code_tree"] } });
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingEditor(false);
    }
  };

  const fetchVersions = async () => {
    try {
      const { data } = await engineApi.getVersions(workspace);
      setRepoVersions([...data, { name: "main", repo_name: workspace }, { name: "All" }]);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDocVersions = async () => {
    try {
      const { data } = await engineApi.getDocVersions({
        full_repo_name: workspace,
        file_path: parseFilename(filename),
      });
      if (data?.versions) {
        console.log("data?.versions");
        console.log(data?.versions);

        const versions = data.versions.map((version) => parseDocVersion(version));
        setDocVersions([...versions]);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const fetchDoc = async () => {
    setLoadingEditor(true);
    try {
      const {
        data: { content },
      } = await docsApi.getDoc({
        filename: parseGeneratedFilenameWithVersion(filename, docVersion),
        type: type.toUpperCase(),
      });

      setDocumentation(content);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingEditor(false);
    }
  };

  const setCurrentFile = (label) => {
    navigate(
      {
        pathname: `/documentation/${type}/${encodeURIComponent(workspace)}/${encodeURIComponent(
          label
        )}`,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };

  const setCurrentWorkspace = (label, workspaceType) => {
    const newWorkspace = allWorkspaces?.find((workspace) => workspace?.name === label);

    navigate({
      pathname: `/documentation/${newWorkspace?.type?.toLowerCase()}/${encodeURIComponent(
        label
      )}/create`,
      search: searchParams.toString(),
    });
  };

  const setRepoVersion = (label) => {
    searchParams.set("repo_version", label);

    setSearchParams(searchParams);
  };

  const setDocVersion = (label) => {
    searchParams.set("doc_version", label);
    setSearchParams(searchParams);
  };

  const checkAndSetDocVersion = (label = repoVersion) => {
    console.log(label);
    console.log(docVersions);
    const hasVersion = docVersions.find((version) => version === label);
    if (hasVersion) {
      console.log("VERSION CHANGESD");
      setDocVersion(label);
    }
  };

  const handleAiToggle = () => {
    setShowAiWriter((showAiWriter) => !showAiWriter);
  };

  const handleSetAiRelatedDoc = (doc) => {
    navigate(
      `/documentation/repo/${encodeURIComponent(doc["repo_name"])}/${encodeURIComponent(
        `${doc["repo_name"]}/docs/${doc["filepath"]}/main.md`
      )}`
    );
  };

  useEffect(() => {
    const handleKCmd = handleCmdKeysGenerator(handleSave, handleAiToggle);

    document.addEventListener("keydown", handleKCmd);

    return () => {
      document.removeEventListener("keydown", handleKCmd);
    };
  }, []);

  useEffect(() => {
    fetchWorkspaces();
    fetchOrganisations();
  }, []);

  useEffect(() => {
    fetchWorkspaceId();
  }, [workspace]);

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceDocs();
    }
  }, [workspaceId, workspace, filename]);

  useEffect(() => {
    if (filename) {
      setRenamedFilename(
        isUserDoc ? parseUserFilename(filename) : parseGeneratedFilename(filename)
      );
    }
  }, [filename, workspaceDocs.length, workspace]);

  useEffect(() => {
    if (type === "repo" && workspaceId) {
      fetchVersions();
    }
  }, [workspaceId, filename, workspace]);

  useEffect(() => {
    if (type === "repo" && workspaceId) {
      if (repoVersion === "All") {
        fetchRepo();
      } else {
        fetchRepoVersion();
      }
    } else {
      setRepo({});
    }
  }, [repoVersion, type, workspaceId]);

  useEffect(() => {
    if (userDir?.flattened && !isCreateMode) {
      fetchDocVersions();
      fetchDoc();
    }
  }, [filename, userDir?.flattened, workspace]);

  useEffect(() => {
    if (filename && userDir?.flattened) {
      fetchDoc();
    }
    if (docVersion === "main") {
      setShowCodeDiff(false);
    }
  }, [docVersion]);

  useEffect(() => {
    if (docVersions.indexOf(docVersion) === -1) {
      searchParams.delete("doc_version");
      setSearchParams(searchParams);
    }
  }, [docVersions]);

  useEffect(() => {
    if (isCreateMode) {
      setRenamedFilename("");
      setDocumentation("");
      setPreview("live");
    }
  }, [isCreateMode, location.pathname]);

  const handleDownload = async () => {
    downloadDocumentation(documentation, renamedFilename);
  };

  const handleSave = async () => {
    console.log("ran");
    if (renamedFilename) {
      console.log("ran");
      setSaving(true);
      try {
        if (isUserDoc) {
          let redirectFilename = "";
          if (checkCreateMode(location.pathname)) {
            const {
              data: { newFilename },
            } = await docsApi.createDoc({
              documentation,
              filename: renamedFilename,
              workspace,
              type: type.toUpperCase(),
            });

            redirectFilename = newFilename;
          } else {
            const {
              data: { newFilename },
            } = await docsApi.updateDoc({
              documentation,
              filename: renamedFilename,
              workspace,
              defaultFilename: filename,
              type: type.toUpperCase(),
            });

            redirectFilename = newFilename;
          }

          navigate(
            {
              pathname: `/documentation/${type}/${encodeURIComponent(
                workspace
              )}/${encodeURIComponent(redirectFilename)}`,
              search: searchParams.toString(),
            },
            { replace: true }
          );
        } else {
          const {
            data: { documentation: newDocumentation },
          } = await engineApi.updateDocumentation({
            full_repo_name: workspace,
            file_path: parseGeneratedFilename(filename),
            documentation,
          });
          fetchDocVersions();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSaving(false);
      }
    }
  };

  const handleGenerateDocumentation = async () => {
    setGenerating(true);
    try {
      const response = await engineApi.generateDocumentation({
        full_repo_name: workspace,
        file_path: parseGeneratedFilename(filename),
      });
      fetchDoc();
    } catch (err) {
      console.error(err);
    } finally {
      setGenerating(false);
    }
  };

  const handleCreateDoc = () => {
    setDocumentation("");
    navigate({
      pathname: `/documentation/${type}/${encodeURIComponent(workspace)}/create`,
      search: searchParams.toString(),
    });
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      localStorage.setItem("DOC_TOUR_SHOWN", true);
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Box width={"100%"} display={"flex"} height={"calc(100vh - 70px)"} position={"relative"}>
      <Joyride
        steps={DASHBOARD_STEPS}
        continuous
        callback={handleJoyrideCallback}
        run={!localStorage.getItem("DOC_TOUR_SHOWN")}
        styles={{
          options: {
            arrowColor: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
            backgroundColor: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.mode === "dark" ? "#fff" : "#000",
            zIndex: 1000,
          },
        }}
      />
      <FileSidebar
        repoVersions={repoVersions}
        repoVersion={repoVersion}
        loadingFileSidebar={loadingFileSidebar}
        setRepoVersion={setRepoVersion}
        workspaces={allWorkspaces}
        setCurrentWorkspace={setCurrentWorkspace}
        type={type}
        repo={repo}
        workspaceDocs={workspaceDocs}
        userDir={userDir}
        filename={filename}
        workspace={workspace}
        setCurrentFile={setCurrentFile}
        checkAndSetDocVersion={checkAndSetDocVersion}
      />

      <Paper
        sx={{
          width: "60%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          mx: "auto",
          px: 2,
          overflowY: "auto",
        }}
      >
        <Box display={"flex"} pt={1} justifyContent={"space-between"} alignItems={"center"}>
          <TextField
            data-step="filename"
            sx={{ flexGrow: 2, mr: 2 }}
            value={renamedFilename}
            disabled={!isUserDoc}
            variant="standard"
            onChange={(e) => setRenamedFilename(e.target.value)}
            placeholder="Enter filename"
          />
          {!isUserDoc && docVersions?.length > 0 && (
            <Autocomplete
              sx={{
                mr: 2,
                flexGrow: 1,
              }}
              disablePortal
              options={docVersions}
              value={docVersion}
              onChange={(e, newVal) => {
                if (newVal) {
                  setDocVersion(newVal);
                }
              }}
              renderInput={(params) => <TextField {...params} label="Doc Version" />}
            />
          )}

          {docVersion !== "main" && (
            <Button onClick={() => setShowCodeDiff(!showCodeDiff)} sx={{ mr: 2 }}>
              {showCodeDiff ? "Close diff" : "Show diff"}
            </Button>
          )}

          <Box display={"flex"} alignItems={"center"}>
            <Button
              variant="text"
              disabled={!renamedFilename}
              onClick={handleDownload}
              startIcon={<Download />}
              color="secondary"
            >
              Download
            </Button>
            <Button
              variant="contained"
              sx={{
                ml: 2,
              }}
              onClick={async () => {
                setSaving(true);
                await handleSave();
                setSaving(false);
              }}
              startIcon={<Save />}
              disabled={saving || !renamedFilename}
            >
              Save
            </Button>
          </Box>
        </Box>

        <DocumentationTabs
          hasDocumentation={documentation ? true : false}
          isUserDoc={isUserDoc}
          view={docCodeView}
          setView={setDocCodeView}
        />

        {docCodeView === 1 && (
          <DocView
            loadingEditor={loadingEditor}
            showCodeDiff={showCodeDiff}
            setShowCodeDiff={setShowCodeDiff}
            documentation={documentation}
            generating={generating}
            handleGenerateDocumentation={handleGenerateDocumentation}
            setDocumentation={setDocumentation}
            isUserDoc={isUserDoc}
            preview={preview}
          />
        )}
        {docCodeView === 0 && (
          <ShowCode
            currentFile={{
              ...getObjectSafely(repo?.flattened, parseGeneratedFilename(filename)),
              name: parseGeneratedFilename(filename),
            }}
            repoName={workspace}
          />
        )}

        <AddOptions preview={preview} setPreview={setPreview} handleCreateDoc={handleCreateDoc} />
      </Paper>

      {showAiWriter && (
        <WriteAiSidebar
          handleSetAiRelatedDoc={handleSetAiRelatedDoc}
          documentation={documentation}
          setDocumentation={setDocumentation}
        />
      )}
    </Box>
  );
};

export default DocumentationEditor;
