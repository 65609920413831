import { Badge, Box, Button, MenuItem, Select, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import GitHubIcon from '@mui/icons-material/GitHub';
import { green } from '@mui/material/colors';
import userApi from '../../api/user';
import { UserContext } from '../root';
import githubIntegrationApi from '../../api/github';
import requestApi from '../../api/requests';
import { useNavigate } from 'react-router-dom';

const GithubOnboarding = () => {
  const theme  = useTheme()
  const [installations, setInstallations] = useState([])
  const [installationId, setInstallationId] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const [user,setUser] = useState({})
  const githubIntegration = user?.integrations?.find((integration)=>integration.type==="GITHUB")


  const fetchUser = async ()=>{
    setLoading(true)
    try{
      const {data} = await userApi.profile();
      console.log(data)
      setInstallationId(data?.integrations?.find((integration)=>integration.type==="GITHUB").installation_id)
      setUser({...data})
    }catch(err){
      console.error(err)
    }
  }


  const fetchInstallations = async()=>{
    console.log(user)
    console.log("githubIntegration")
    console.log(githubIntegration?.installation_id)
    if(githubIntegration){
      console.log("ran")
      try{
        const {data} = await userApi.getInstallations({
          access_token: githubIntegration['access_token']
        });

        setInstallations([...data])
        console.log(data)
      }catch(err){
        console.log(err)
      }
    }

  }

  useEffect(()=>{
    fetchUser()
  },[])

  useEffect(()=>{
    if(user?.username){
      fetchInstallations()

    }
  },[user?.username])

  // useEffect(()=>{
  //   if(githubIntegration?.installation_id){
  //     navigate("/repositories")
  //   }

  // },[githubIntegration?.installation_id])

  const handleOrgSave= async ()=>{
    setLoading(true)
      if(installations.find((installation)=>installationId===installation.id).target_type==="User"){
        try{
          const response = await userApi.updateInstallationId({
            installation_id: installationId
          })
          console.log(response)
      }catch(err){
        console.log(err)
      }
    }else{
      try{
        const response = await requestApi.sendRequest({
          installation_id: installationId

        })

        console.log(response)
      }catch(err){
        console.error(err)
      }
    }
    const {data} =  await githubIntegrationApi.refreshToken()
    console.log(data)
    if(data['redirect_url']){
      window.location.replace(data['redirect_url'])
    }else{
      window.location.reload()
    }

  }
  return (
    <Box width={"100vw"} sx={{
      color: theme.palette.mode=="dark"? "white": "black",

    }} >
      <Box margin={"auto"} sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      }} >
        {/* <Typography sx={{
                  fontSize:"4rem",
        }} >Auto<span style={{
                color: theme.palette.primary.main,
                fontWeight:"bold"
          }}>KT</span></Typography> */}
          <img src='https://autokt.io/wp-content/uploads/2023/08/AutoKT_transparent.png' margin="auto" width={"150px"} />
        <DoubleArrowIcon sx={{
          color: "secondary.main",
          fontSize:"4rem",

        }} />
        <GitHubIcon sx={{
                  fontSize:"4rem",
        }} />
      </Box>
      <Box sx={{
        display:"flex",
        flexDirection:"column",
        width:"50vw",
        margin:"auto",
      }}>
          { !githubIntegration && <>  <img src='/integrate.gif' width={"100%"} />

        <h1>Step 1: Authorize github</h1>
        <p>Visit this <a target='_blank' rel='noreferrer' href='https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt'>link</a> to authorize github. You will be redirected to the login page. Please use AutoKT credentials to log in. <br /> This will link the authorization with your user.</p>
        <a target='_blank' rel='noreferrer' href='https://github.com/login/oauth/authorize?client_id=Iv1.3f676959d096e777&state=authorize-autokt'><Button color='primary'  variant='contained' >Authorize github</Button></a>
        <img src='/redirect.png' width={"100%"} />
        </>}



        { githubIntegration &&  <><h1>Step 2: Select app installation/ Install app.</h1>
       <Select fullWidth value={installationId} placeholder='Please select installation' onChange={(e)=>{
          console.log(e.target.value)
          setInstallationId(e.target.value)
        }}>
          {installations?.map((installation)=><MenuItem sx={{
            mb:2,
            display:"flex",
            justifyContent:"space-between"
          }} key={installation?.id} value={installation?.id}> {installation.account.login} {`(${installation?.target_type})`}</MenuItem>)}
        </Select>
        <Button onClick={handleOrgSave}>{ installations.find((installation)=>installation.id===installationId)?.target_type==="User"? "Save": "Request"}</Button>

        <p>If you don't see your account/organisation in the list visit this <a target='_blank' rel='noreferrer' href='https://github.com/apps/autokt'>link</a> or click on the install app button to install the autokt github app</p>
        <a target='_blank' rel='noreferrer' href='https://github.com/apps/autokt'><Button color='primary'  variant='contained' >Install github app</Button></a>

      <img src='/install.png' width={"100%"} />
      </>}
     {githubIntegration?.installation_id && <h1 style={{
          color : theme.palette.success.light
        }}>You are all done!</h1>}
      </Box>

    </Box>
  )
}

export default GithubOnboarding
