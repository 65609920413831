
import React from 'react'
import { Outlet } from 'react-router-dom';

const Onboarding = () => {
  return (
   <Outlet />
  )
}

export default Onboarding
