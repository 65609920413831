export   const downloadDocumentation = (documentation, filename) => {

  // Create a Blob with the text content and specify the MIME type as plain text.
  const blob = new Blob([documentation], { type: 'text/plain' });

  // Create a URL for the Blob object.
  const url = URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the download.
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename||'doc'}.md`;

  // Trigger the click event to start the download.
  a.click();

  // Release the URL object.
  URL.revokeObjectURL(url);
};
