import { Box, Button, CircularProgress, LinearProgress, Typography, useTheme } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import engineApi from "../../../api/engine";
import { useNavigate } from "react-router-dom";
import SingleMDEditor from "../../components/SingleMDEditor";

const ShowDoc = ({
  currentFile,
  editable,
  version,
  handleDocUpdate,
  full_repo_name,
  documentations,
  setDocumentations,
}) => {
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const versionName = version !== "main" ? version.split("/").slice(-1)[0].slice(0, -3) : "main";
  const theme = useTheme();
  const fetchDoc = async () => {
    setLoading(true);
    console.log(currentFile.name);
    try {
      const {
        data: { documentation },
      } = await engineApi.getDocumentation({
        full_repo_name,
        file_path: currentFile.name,
        version: versionName,
      });
      console.log("documentation");
      console.log(documentation);

      let newDocumentation = [];
      if (version !== "main") {
        newDocumentation = documentations[currentFile.name].map((doc) => {
          if (doc.version === version) {
            doc.documentation = documentation;
          }
          return doc;
        });
      } else {
        newDocumentation = [{ version: "main", documentation }];
      }

      console.log(newDocumentation);
      setDocumentations({ ...documentations, [currentFile.name]: [...newDocumentation] });
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!documentations[currentFile.name]?.documentation && currentFile?.name) {
      fetchDoc();
    }
  }, [currentFile.name, version]);

  const handleGenerateDocumentation = async () => {
    setGenerating(true);
    try {
      const response = await engineApi.generateDocumentation({
        full_repo_name,
        file_path: currentFile.name,
      });
      console.log(response);
    } catch (err) {
      console.error(err);
    } finally {
      navigate(0);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (
    !documentations[currentFile?.name]?.find((doc) => {
      const versionValue = version ? version : "main";
      return doc.version === versionValue;
    })?.documentation
  ) {
    if (editable) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
              variant="h6"
            >
              {generating ? "Generating documentation" : "No documentation found for this file."}
            </Typography>
            {generating ? (
              <LinearProgress />
            ) : (
              <Button
                disabled={generating}
                variant="contained"
                width={"fit-content"}
                onClick={handleGenerateDocumentation}
              >
                Generate
              </Button>
            )}
          </Box>
        </Box>
      );
    }

    if (!editable) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
              variant="h6"
            >
              Select file to view documentation
            </Typography>
          </Box>
        </Box>
      );
    }
  }

  if (!editable) {
    return (
      <>
        {" "}
        <MDEditor.Markdown
          wrapperElement={{
            "data-color-mode": theme.palette.mode,
          }}
          style={{
            overflowY: "scroll",
            padding: "20px 20px",
            height: "100%",
            maxHeight: "100%",
          }}
          source={
            documentations[currentFile?.name].find((doc) => {
              const versionValue = version ? version : "main";
              return doc.version === versionValue;
            })?.documentation ?? ""
          }
        />
      </>
    );
  }

  return (
    <>
      <SingleMDEditor
        value={
          documentations[currentFile?.name].find((doc) => {
            const versionValue = version ? version : "main";
            return doc.version === versionValue;
          })?.documentation ?? ""
        }
      />
      <MDEditor
        data-color-mode={theme.palette.mode}
        style={{
          flexGrow: 1,
        }}
        value={
          documentations[currentFile?.name].find((doc) => {
            const versionValue = version ? version : "main";
            return doc.version === versionValue;
          })?.documentation ?? ""
        }
        onChange={(val) => {
          handleDocUpdate(val);
        }}
      />
    </>
  );
};

export default ShowDoc;
