import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import ModeTypography from '../../../components/ModeTypography'
import Loader from '../../../components/Loader'
import userApi from '../../../api/user'
import requestApi from '../../../api/requests'

const ShowRequests = () => {
  const [requests, setRequests] = useState([])
  const [user,setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [role, setRole] = useState([])

  const fetchUser = async ()=>{
    try{
      const {data} = await userApi.profile()
      console.log(data)
      setUser({...data})
    }catch(err){
      console.log(err.response.status)
      if(err.response.status===401){
        localStorage.setItem("USER_TOKEN","")
      }
    }
  }

  const fetchRequests = async ()=>{
    setLoading(true)
    try{
      const {data} = await requestApi.getRequests();
      console.log(data)
      setRequests([...data])
    }catch(err){
      console.error(err)
    }finally{
      setLoading(false)
    }
  }


  useEffect(()=>{
    fetchUser()
  },[])


  useEffect(()=>{
    fetchRequests()
  },[])

  const handleAcceptRequest = async (request, index)=>{
    console.log(role[index])
    if(role[index]){
      try{
        const {data} = await requestApi.acceptRequest({
          from_user_id: request?.from_user_id,
          role: role[index]
        })
        console.log(data)

      }catch(err){
        console.error(err)
      }finally{
        fetchRequests()
      }
    }

  }

  if(loading){
    return <Loader />
  }

  return (
    <Box px={4} pt={2}>

      {requests.length===0 && <ModeTypography>No pending requests.</ModeTypography>}
      {requests.length>0 &&   <TableContainer sx={{width: "70%", margin:"auto"}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Request By</TableCell>
            <TableCell >Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((request,index) => (
            <TableRow
              key={request['_id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell >{request.from_username}</TableCell>
              <TableCell >{!request?.accepted && <Select value={role[index]||""} onChange={(e)=>{
                const newRole = [...role]
                newRole[index] = e.target.value
                setRole([...newRole])
              }}>
                <MenuItem value="readers">Readers</MenuItem>
                <MenuItem value="editors">Editors</MenuItem>
                <MenuItem value="admins">Admins</MenuItem>
                </Select>}</TableCell>
              <TableCell>{request.accepted? "Accepted":<Button color='secondary' onClick={()=>handleAcceptRequest(request, index)}>Accept</Button>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
  )
}

export default ShowRequests
