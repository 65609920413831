import { Autocomplete, Box, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ShowRepo from "../../../Dashboard/ShowRepo/components/ShowRepo";
import { parseFilename, parseFilenameFromDirectory } from "../utils";
import { AccountTree, Close } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const FileSidebar = ({
  repo,
  repoVersions,
  repoVersion,
  setRepoVersion,
  filename,
  setCurrentFile,
  workspace,
  workspaces,
  type,
  setCurrentWorkspace,
  checkAndSetDocVersion,
  userDir,
  workspaceDocs,
}) => {
  const [view, setView] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();

  const setGeneratedFile = (label) => {
    const fullFilename = `${workspace}/docs/${label}/main.md`;
    setCurrentFile(encodeURIComponent(fullFilename));
    checkAndSetDocVersion();
  };

  useEffect(() => {
    if (!repo?.repo_code) {
      setView(0);
    }
  }, [repo?.repo_code]);

  if (!isOpen) {
    return (
      <AccountTree
        onClick={() => setIsOpen(true)}
        sx={{
          mt: 2,
          px: 1,
          py: 1,
          background: theme.palette.primary.main,
          cursor: "pointer",
        }}
      />
    );
  }

  return (
    <>
      <Box
        sx={{ width: "20%", px: 1 }}
        height={"calc(100vh - 86px)"}
        display={"flex"}
        position={"relative"}
        flexDirection={"column"}
        elevation={4}
      >
        <Close
          onClick={() => setIsOpen(false)}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: 0,
            fontSize: "0.75rem",
            top: 0,
            p: 0.75,
            background: theme.palette.secondary.main,
            borderRadius: "50%",
          }}
        />
        <Autocomplete
          sx={{
            mt: 2,
            mb: 1,
          }}
          disablePortal
          fullWidth
          options={workspaces.map((workspace) => workspace?.name)}
          value={workspace}
          onChange={(e, newVal) => {
            if (newVal) {
              setCurrentWorkspace(newVal);
            }
          }}
          data-step="workspace"
          renderInput={(params) => <TextField {...params} label="Workspace" />}
        />

        <Autocomplete
          sx={{
            mt: 2,
          }}
          disablePortal
          fullWidth
          options={
            view === 0
              ? workspaceDocs.map((doc) => doc?.filename)
              : Object.keys({ ...repo.flattened, ...userDir.flattened })
          }
          value={filename}
          getOptionLabel={(option) => parseFilenameFromDirectory(option)}
          onChange={(e, newVal) => {
            if (newVal) {
              if (view === 1) {
                const type = userDir?.flattened[newVal] ? "MANUAL" : "GENERATED";
                if (type === "GENERATED") {
                  setGeneratedFile(newVal);
                } else {
                  setCurrentFile(newVal);
                }
              } else {
                setCurrentFile(newVal);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} label="Search file" />}
        />
        <Tabs
          value={view}
          onChange={(e, value) => {
            setView(value);
          }}
          aria-label="view tabs"
        >
          <Tab label="Workspace View" {...a11yProps(0)} />
          {Object.keys(repo).length > 0 && <Tab label="Repo View" {...a11yProps(1)} />}
        </Tabs>
        {view === 1 && repo?.repo_code && (
          <Autocomplete
            sx={{
              mt: 2,
              mb: 1,
            }}
            disablePortal
            fullWidth
            options={repoVersions.map((version) => version?.name)}
            value={repoVersion}
            onChange={(e, newVal) => {
              if (newVal) {
                setRepoVersion(newVal);
                checkAndSetDocVersion(newVal);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Docs Version" />}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            maxHeight: "100%",
            overflow: "auto",
          }}
          data-step="workspaces"
        >
          {view === 0 &&
            workspaceDocs?.map((doc) => (
              <Paper
                onClick={() => {
                  setCurrentFile(doc?.filename);
                }}
                elevation={theme.palette.mode === "dark" ? 4 : 1}
                sx={{
                  width: "fit-content",
                  px: 2,
                  py: 2,
                  cursor: "pointer",
                  my: 2,
                  wordBreak: "break-all",
                }}
                key={doc["_id"]}
              >
                <Typography>{parseFilename(doc?.filename, doc?.type)}</Typography>
              </Paper>
            ))}

          {view === 1 && repo?.repo_code && (
            <ShowRepo
              repo={repo["repo_code"]}
              tabLevel={0}
              currentFile={filename}
              setCurrentFile={setGeneratedFile}
            />
          )}

          {view === 1 && repo?.repo_code && (
            <ShowRepo
              repo={userDir["repo_code"]}
              tabLevel={0}
              currentFile={filename}
              setCurrentFile={setCurrentFile}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default FileSidebar;
