import axios from "axios"
import autoktAxios, { BASE_API_URL } from "./axios_autokt"
import githubAxios, { GITHUB_INTEGRATION_URL } from "./axios_github"


const sendRequest = (payload)=>autoktAxios.post(`${BASE_API_URL}/requests`, {...payload})
const getRequests = ()=>autoktAxios.get(`${BASE_API_URL}/requests`)
const acceptRequest  = (payload)=> autoktAxios.post(`${BASE_API_URL}/requests/accept`, payload)
const getSentInvites = ()=>autoktAxios.get(`${BASE_API_URL}/invites/sent`)

const requestApi = {
  sendRequest,
  getRequests,
  getSentInvites,
  acceptRequest
}

export default requestApi
