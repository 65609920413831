import React, { useContext, useEffect, useState } from "react";
import githubIntegrationApi from "../../api/github";
import { UserContext } from "../root";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AddCircle, ArrowLeftOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Docs from "./ShowRepo/EditDocs";
import { useNavigate } from "react-router-dom";
import WelcomeScreen from "./WelcomeScreen";
import SearchIcon from "@mui/icons-material/Search";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";

const ListRepos = () => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [repos, setRepos] = useState([]);
  const navigate = useNavigate();
  const [selecterRepoId, setSelectedRepoId] = useState("");
  const [editDescription, setEditDescription] = useState({
    full_repo_name: "",
    description: "",
  });
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const analyzedLoader = repos.reduce((prev, curr) => !curr?.analyzed, false);
  const filteredRepos = search
    ? repos.filter((repos) => repos?.name.toUpperCase().includes(search.toUpperCase()))
    : [...repos];

  console.log(analyzedLoader);

  const fetchRepos = async () => {
    console.log("ran");
    try {
      if (user?.org_id) {
        const { data } = await githubIntegrationApi.listUserRepos();
        console.log("data");
        console.log(data);
        setRepos([...data]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const approveDescription = async () => {
    try {
      const { data } = await githubIntegrationApi.editRepoDescription({ ...editDescription });
      setEditDescription({ ...editDescription, full_repo_name: "", editDescription: "" });
      console.log(data);
      fetchRepos();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRepos();
  }, []);

  useEffect(() => {
    let timer = "";
    if (analyzedLoader && repos.length > 0) {
      console.log("Added");
      timer = setInterval(fetchRepos, 60000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [analyzedLoader]);

  if (loading) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: "50vh",
          left: "50vw",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (selecterRepoId) {
    return (
      <>
        <ArrowLeftOutlined
          sx={{
            position: "absolute",
            right: 4,
            fontSize: "4rem",
            cursor: "pointer",
            top: 4,
          }}
          onClick={() => setSelectedRepoId("")}
        />
        <Docs repo={repos.find((repo) => repo.name === selecterRepoId)["repo_code"]} />
      </>
    );
  }

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TextField
        placeholder="Enter repo name"
        id="outlined-start-adornment"
        sx={{ width: "95%", marginTop: 2 }}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid
        container
        columnSpacing={4}
        marginTop={2}
        paddingX={2}
        position={"relative"}
        rowSpacing={2}
      >
        <AddCircle
          sx={{
            position: "fixed",
            bottom: 4,
            fontSize: "5rem",
            cursor: "pointer",
            color: "secondary.main",
            right: 4,
            zIndex: 3,
          }}
          onClick={() => navigate("/repositories/add")}
        />
        {repos.length == 0 && (
          <SwitchAccessShortcutAddIcon
            sx={{
              position: "fixed",
              bottom: 60,
              fontSize: "7rem",
              cursor: "pointer",
              color: theme.palette.mode === "dark" ? "secondary.main" : "secondary.main",
              right: 60,
              transform: "rotate(100deg)",
            }}
            className='"animate__animated animate__bounce"'
          />
        )}
        {repos.length == 0 && <WelcomeScreen />}
        {repos.length > 0 &&
          filteredRepos.map((repo) => {
            return (
              <Grid item xs={4} sx={{ cursor: "pointer" }} key={repo.name}>
                <Paper
                  sx={{
                    padding: 4,
                    position: "relative",
                    alignSelf: "stretch",
                  }}
                >
                  <Box
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography variant="h6">{repo.name}</Typography>
                    {!repo?.analyzed && (
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          paddingY: 1,
                        }}
                        variant="body2"
                      >
                        <HourglassBottomIcon
                          sx={{
                            color: "secondary.main",
                          }}
                        />
                        We are analyzing your repo. This can take several minutes
                      </Typography>
                    )}
                    {repo?.analyzed && (
                      <>
                        <Button
                          marginLeft={2}
                          onClick={() => {
                            navigate(`/documentation/repo/${encodeURIComponent(repo.name)}/create`);
                          }}
                        >
                          View Docs
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            navigate(`/documentation/repo/${encodeURIComponent(repo.name)}/create`);
                          }}
                        >
                          Edit Docs
                        </Button>
                      </>
                    )}
                  </Box>

                  <details>
                    <summary>
                      Description
                      {repo.description.draft && (
                        <span
                          style={{
                            width: "fit-content",
                            padding: "5px 10px 5px 10px",
                            backgroundColor: theme.palette.accent.main,
                            marginLeft: "10px",
                            borderRadius: "15px",
                            border: "1px dashed black",
                            color: "#000",
                          }}
                        >
                          draft
                        </span>
                      )}
                    </summary>
                    <div>
                      {editDescription.full_repo_name ? (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <DoneAllIcon
                            sx={{
                              color: "success.light",
                              display: "block",
                            }}
                            onClick={approveDescription}
                          />
                          <CloseIcon
                            x={{
                              color: "error.light",
                              display: "block",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditDescription({
                                ...editDescription,
                                full_repo_name: "",
                                description: "",
                              });
                            }}
                          />
                        </Box>
                      ) : (
                        <EditIcon
                          sx={{
                            color: "success.light",
                            display: "block",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditDescription({
                              ...editDescription,
                              full_repo_name: repo.name,
                              description: repo.description.content,
                            });
                          }}
                        />
                      )}
                      {editDescription.full_repo_name ? (
                        <TextField
                          value={editDescription.description}
                          onChange={(e) => {
                            setEditDescription({ ...editDescription, description: e.target.value });
                          }}
                          sx={{
                            width: "100%",
                            height: "content-fit",
                          }}
                          multiline
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {repo.description.content.split(". ").join(".\n")}
                        </Typography>
                      )}
                    </div>
                  </details>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ListRepos;
