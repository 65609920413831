import {
  Box,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import MDEditor from "@uiw/react-md-editor";
import engineApi from "../../api/engine";

const Query = () => {
  const theme = useTheme();
  const [query, setQuery] = useState("");
  const [queryResponse, setQueryResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const handleQuery = async () => {
    setLoading(true);
    try {
      const { data } = await engineApi.getQueryResponse({
        query,
      });
      console.log(data);
      setQueryResponse(data["query_response"]["response"]);
      if (data["query_response"]["docs"]) {
        setDocs([...data["query_response"]["docs"]]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        width: "90vw",
        pt: 2,
        height: "80vh",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: theme.palette.mode === "dark" ? "white" : "black",
      }}
    >
      <Typography variant="h2">
        Auto<span style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>KT</span>{" "}
        <span style={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>AI</span>
      </Typography>
      <Typography variant="h6">
        Your codebase <span style={{ color: theme.palette.secondary.main }}>assistant.</span>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          backgroundColor: theme.palette.mode === "dark" ? "background.default" : "white",
          fontWeight: "bold",
          px: 2,
          py: 1,
          fontSize: "0.75rem",
        }}
      >
        Note: AutoKT AI uses your existing main documentation to answer any queries.
      </Typography>
      <TextField
        placeholder="Ask me anything"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        sx={{
          width: "60%",
          marginTop: 2,
        }}
        disabled={loading}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleQuery();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <SendIcon
                sx={{
                  color: "primary.main",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!loading) {
                    handleQuery();
                  }
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Paper
        elevation={2}
        sx={{
          width: "60%",
          backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
          maxHeight: "60vh",
          overflowY: "autok",
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <MDEditor.Markdown
            wrapperElement={{
              "data-color-mode": theme.palette.mode,
            }}
            style={{
              padding: "2rem 1rem",
            }}
            source={queryResponse ?? ""}
          />
        )}
        {!loading && docs.length > 0 && (
          <Box
            sx={{
              px: 2,
              py: 4,
            }}
          >
            <Typography variant="h4">Related documentation</Typography>
            {docs.map((doc) => (
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
                href={`/documentation/repo/${encodeURIComponent(
                  doc["repo_name"]
                )}/${encodeURIComponent(`${doc["repo_name"]}/docs/${doc["filepath"]}/main.md`)}`}
              >
                <Typography
                  sx={{
                    py: 1,
                    borderBottom: "1px solid " + theme.palette.secondary.main,
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  }}
                >
                  {doc.filepath}
                </Typography>
              </a>
            ))}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Query;
