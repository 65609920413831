import autoktAxios, { BASE_API_URL, GITHUB_INTEGRATION_URL } from "./axios_autokt";
import githubAxios from "./axios_github";


const listDocs = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs`,payload)
const getDoc = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs/single`,payload)
const createDoc = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs/create`,payload)
const updateDoc = (payload)=>autoktAxios.put(`${BASE_API_URL}/docs/single`,payload)
const getWorkspaceId = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs/workspace_id`,payload)

const generateDoc = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs/generate`,payload)
const askDoc = (payload)=>autoktAxios.post(`${BASE_API_URL}/docs/ask`,payload)

const docsApi = {
    listDocs,
    getDoc,
    getWorkspaceId,
    createDoc,
    updateDoc,
    generateDoc,
    askDoc
}

export default docsApi
