import React, { useEffect, useState } from 'react'
import inviteApi from '../../../api/invite'
import { Box, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ModeTypography from '../../../components/ModeTypography'
import Loader from '../../../components/Loader'
import userApi from '../../../api/user'

const ShowInvitesV2 = ({user, invites}) => {

  const handleAcceptInvite = async (invite)=>{
    try{
      const {data} = await inviteApi.acceptInvite({
        username: user?.username,
        from_user_email: invite?.from_user_email,
        org_id: invite?.org_id
      })
      console.log(data)

    }catch(err){
      console.error(err)
    }
  }

  return (
    <Box px={4} pt={2}>

      {invites.length===0 && <ModeTypography>No pending invites.</ModeTypography>}
      {invites.length>0 &&   <TableContainer sx={{width: "70%", margin:"auto"}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Organisation Name</TableCell>
            <TableCell >Invitee Email</TableCell>
            <TableCell >Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invites.map((invite) => (
            <TableRow
              key={invite['_id']}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {invite.org_name}
              </TableCell>
              <TableCell >{invite.from_user_email}</TableCell>
              <TableCell >{invite.role}</TableCell>
              <TableCell sx={{
                display:"flex",
                justifyContent:"flex-start"
              }} align='center' >{invite?.approved!=="False"?<Typography>Accepted</Typography>:<><Button onClick={()=>handleAcceptInvite(invite)}>Accept</Button> <Divider orientation='vertical' flexItem /> <Button color='secondary'>Deny</Button> </> }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
  )
}

export default ShowInvitesV2
