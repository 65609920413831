import { ExtensionPriority, PlainExtension } from "@remirror/core";

export class SlashMenuExtension extends PlainExtension {
  constructor({ showMenu, hideMenu }) {
    super({ showMenu });
    this.showMenu = showMenu;
    this.hideMenu = hideMenu;
  }
  get name() {
    return "slash";
  }

  get priority() {
    return ExtensionPriority.High;
  }

  createKeymap() {
    const showMenu = this.showMenu;
    const hideMenu = this.hideMenu;
    return {
      "/": function ({ state, dispatch, view, ...rest }) {
        // Prevent the default '/' from being inserted.
        dispatch(state.tr.insertText("/"));

        // Call the method to show your menu.
        // You would need to implement `showMenu` to update your React component's state
        // so that it knows to render the menu at the current selection.
        showMenu(view);
        return true;
      },
    };
  }
}

export default SlashMenuExtension;
