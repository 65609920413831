import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'

const ModeTypography = (props) => {
  const theme = useTheme()
  return (
    <Typography color={theme.palette.mode==="dark"? "white": "black"} {...props}>{props.children}</Typography>
  )
}

export default ModeTypography
